<template>
  <h2>Datenschutzerklärung</h2>
  <h3>1. Allgemeine Hinweise</h3>
  <p>
    Die Sicherheit und die Wahrung der Vertraulichkeit Ihrer personenbezogenen
    Daten ist uns ein wichtiges Anliegen. Im Folgenden finden Sie Informationen,
    in welchem Rahmen wir Ihre Daten verarbeiten und getroffene Maßnahmen, die
    dem Schutz Ihrer Privatsphäre als Nutzer dieser Seite dienen. Alle hier
    erhobenen Daten werden in unserem Verantwortungsbereich entsprechend den
    Vorschriften der Europäischen Datenschutz-Grundverordnung (DSGVO) und in
    Übereinstimmung mit den für uns geltenden landesspezifischen
    Datenschutzbestimmungen wie das Bundesdatenschutzgesetz neu (BDSG neu) oder
    das Telemediengesetz (TMG) streng vertraulich behandelt. Die Verarbeitung
    erfolgt entweder gemäß Art. 6 Abs. 1 lit. a DSGVO auf Basis Ihrer
    Einwilligung, gemäß Art. 6 Abs. 1 lit b DSGVO zur Erfüllung oder Anbahnung
    eines Vertrages mit Ihnen, gemäß Art. 6 Abs. 1 lit. c DSGVO zur Erfüllung
    einer rechtlichen Verpflichtung von uns oder auf der Grundlage gemäß Art. 6
    Abs. 1 lit. f DSGVO zur Wahrung berechtigter Interessen von uns.
  </p>
  <h3>2. Verantwortliche Stelle</h3>
  <p>
    Barkhausen Institut gGmbH<br />
    Würzburger Str. 46<br />
    01187 Dresden
  </p>

  <p>
    Vertretungsberechtigter Geschäftsführer: Prof. Dr. Gerhard Fettweis<br />
    Tel.: +49 351 46339161<br />
    E-Mail: info(at)barkhauseninstitut.org
  </p>

  <p>
    Für Fragen, Anregungen oder Kommentare zum Thema Datenschutz wenden Sie sich
    bitte an unsere Datenschutzbeauftragte:
  </p>

  <p>
    Kerstin Herschel<br />
    Externe Datenschutzbeauftragte<br />
    PRODATIS CONSULTING AG<br />
    Landhausstraße 8<br />
    01067 Dresden
  </p>

  <p>
    Telefon: +49 351 266 23 30<br />
    E-Mail: dsb(at)prodatis.com
  </p>
  <h3>Erhebung und Verarbeitung persönlicher Informationen</h3>
  <p>
    Personenbezogene Daten sind alle Informationen, die sich auf eine
    identifizierte oder identifizierbare natürliche Person beziehen. Als
    identifizierbar wird eine natürliche Person gesehen, die direkt oder
    indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
    zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
    oder mehreren besonderen Merkmalen identifiziert werden kann, oder auch die
    Ausdrücke der physischen, physiologischen, genetischen, psychischen,
    wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
    Person sind.
  </p>
  <p>
    Die Verarbeitung personenbezogener Daten kann mit oder ohne Hilfe von
    DV-Anlagen erfolgen und umfasst folgende Tätigkeiten: das Erheben, das
    Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder
    Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung
    durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung,
    den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die
    Vernichtung.
  </p>
  <p>
    Wir verarbeiten Ihre persönlichen Informationen, die Sie uns über unsere
    Webseite mitteilen, nur zur Korrespondenz mit Ihnen und nur für den Zweck,
    zu dem Sie uns die Daten zur Verfügung gestellt haben.
  </p>
  <p>
    Bei jedem Zugriff auf unsere Webseite erheben und speichern wir automatisch
    und vorübergehend Informationen, die Ihr Browser während Ihres Nutzens auf
    unserer Webseite und bei jedem Abruf einer Datei bei uns hinterlässt in
    einer sogenannten Protokolldatei. Im Einzelnen werden über jeden
    Zugriff/Abruf folgende Daten gespeichert:
  </p>
  <ul>
    <li>IP-Adresse</li>
    <li>Datum und Uhrzeit des Abrufs</li>
    <li>Aufgerufene Seite/Name der abgerufenen Datei</li>
    <li>Webbrowser und anfragende Domain</li>
    <li>Übertragene Datenmenge</li>
    <li>Meldung, ob der Zugriff erfolgreich war</li>
  </ul>
  <p>
    Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
    berechtigten Interesses an der Verbesserung der Stabilität und
    Funktionalität unserer Webseite. Eine Weitergabe oder anderweitige
    Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor,
    die Server-Logfiles nachträglich zu überprüfen, sollten konkrete
    Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
  </p>
  <h3>4. Verwendung und Weitergabe persönlicher Informationen</h3>
  <p>
    Ihre persönlichen Daten (z.B. Name, Vorname, Telefon, Anschrift, E-Mail
    Adresse), die Sie uns über diese Webseite z.B. über unser Kontaktformular
    mitteilen, werden nur zur Korrespondenz mit Ihnen, zur Bearbeitung Ihrer
    Anfrage oder für den Zweck verarbeitet, zu dem Sie uns die Daten zur
    Verfügung gestellt haben.
  </p>
  <p>
    Eine sonstige Auswertung der Daten, außer für statistische Zwecke und dann
    grundsätzlich in anonymisierter Form, erfolgt nicht.
  </p>
  <p>
    Soweit wir zur Durchführung bzw. Erfüllung von Aufgaben für uns
    Dienstleister in Anspruch nehmen, werden die Vertragsverhältnisse nach den
    Bestimmungen der Europäischen Datenschutzgrundverordnung (EU-DSGVO) und des
    Bundesdatenschutzgesetz neu (BDSG neu) schriftlich geregelt. Dies betrifft
    folgende Kategorien von Empfängern:
  </p>
  <ul>
    <li>
      IT-Dienstleister zur Gewährleistung der Sicherheit und der Vertraulichkeit
    </li>
    <li>
      Finanzdienstleister zur Abwicklung der Geschäftstätigkeit und Einhaltung
      gesetzlicher
    </li>
    <li>Vorschriften</li>
    <li>Transportdienstleister</li>
    <li>Lieferanten zur Abwicklung von Direktversendungen</li>
  </ul>
  <p>
    Zusätzlich können von uns anonyme Daten mit Partnern geteilt werden, um
    statistische Analysen durchzuführen. Allen diesen Parteien ist es verboten,
    Ihre persönlichen Angaben zu verwenden, sofern sie nicht für unser Angebot
    benutzt werden.
  </p>
  <p>
    Unsere Webseite wird auf OVH gehostet. Wir haben mit OVH einen Vertrag zur
    Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der
    deutschen Datenschutzbehörden bei der Nutzung von OVH vollständig um. Den
    Auftragsverarbeitungsvertrag von OVH finden sie hier.
  </p>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>
