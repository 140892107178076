<template>
  <div class="fixed inset-0 flex items-center justify-center p-3 md:py-12">
    <blur-overlay
      @click="$emit('close')"
      class="absolute inset-0 z-0"
    ></blur-overlay>
    <div
      class="pb-8 max-w-4xl mx-auto h-full bg-white flex flex-col items-start z-10"
    >
      <div
        class="w-full py-2 md:py-3 px-6 relative hot-spot-detail-top-bar flex items-center"
        :class="`bg-blue-500`"
      >
        <button
          class="inline-flex items-center focus:outline-none text-white transition duration-200 ease-in-out"
          :class="`hover:text-blue-200`"
          @click="$emit('close')"
        >
          <BaseIcon name="triangle-back" class="w-3 h-3" />
          <span class="text-xs uppercase font-semibold tracking-wide ml-2">{{
            $t('general.close')
          }}</span>
        </button>
      </div>
      <div class="overlay-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BlurOverlay from './hud-elements/BlurOverlay'
export default {
  name: 'ContentOverlay',
  components: { BlurOverlay }
}
</script>

<style lang="scss">
.overlay-content {
  -moz-hyphens: auto;
  hyphens: auto;
  @apply px-4 overflow-auto;

  @screen md {
    @apply text-lg px-6;
  }

  h2 {
    @apply mt-8 text-2xl text-blue-500 font-bold leading-tight;

    @screen md {
      @apply text-3xl;
    }
  }

  h3 {
    @apply mt-5 text-lg font-bold leading-tight;

    @screen md {
      @apply text-xl;
    }
  }

  p {
    @apply mt-3;
  }

  ul {
    @apply mt-3 list-inside;

    li {
      @apply my-1 flex items-center;

      &:before {
        content: '';
        width: 0.4em;
        height: 0.4em;
        @apply inline-block mr-2 bg-blue-500 rounded-full;
      }
    }
  }

  a {
    @apply font-semibold text-blue-500 transition duration-200 ease-in-out;

    &:hover {
      @apply text-blue-400;
    }
  }
}
</style>
