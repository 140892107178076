<template>
  <button class="rounded-full shadow-custom-sm group" aria-label="Close">
    <span
      tabindex="-1"
      class="transition duration-500 ease-in-out bg-white rounded-full flex items-center justify-center w-12 h-12 group-hover:shadow-white focus:outline-none"
    >
      <BaseIcon name="close" class="w-6 h-6" />
    </span>
  </button>
</template>

<script>
export default {
  name: 'CloseButton'
}
</script>
