<template>
  <transition
    enter-active-class="transition duration-200 ease-in-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-200 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <blur-overlay
      v-if="expanded"
      class="h-screen w-screen fixed inset-0 z-20"
      @click="expanded = false"
    ></blur-overlay>
  </transition>
  <div
    class="h-full z-10 transform transition duration-200 ease-in-out"
    :class="{ '-translate-y-12 z-30': expanded }"
  >
    <div
      class="flex items-center px-2 sm:px-4 h-full font-bold border border-gray-600 bg-white transform"
      @click="expanded = !expanded"
    >
      <span class="uppercase">{{ $i18n.locale }}</span>
      <span class="ml-3">
        <BaseIcon
          :name="expanded ? 'close' : 'chevron-up'"
          class="w-4 h-4"
        ></BaseIcon>
      </span>
    </div>
    <div
      class="flex items-center px-4 h-full font-bold border border-gray-600 bg-white"
      @click="setActiveLanguage(otherLanguage)"
    >
      <span class="font-medium text-gray-700 uppercase">{{
        otherLanguage
      }}</span>
    </div>
  </div>
</template>

<script>
import BlurOverlay from '../BlurOverlay'
export default {
  name: 'MobileLanguageSwitcher',
  components: { BlurOverlay },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    otherLanguage() {
      return this.$i18n.locale === 'de' ? 'en' : 'de'
    }
  },
  methods: {
    setActiveLanguage(lang) {
      this.expanded = false
      this.$i18n.locale = lang
    }
  }
}
</script>

<style scoped></style>
