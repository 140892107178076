<template>
  <button class="rounded-full cursor-pointer group">
    <span
      class="shadow-custom-sm flex items-center bg-white rounded-full p-3 lg:px-3 lg:py-1 text-sm text-gray-800 font-semibold transition duration-500 ease-in-out sm:group-hover:shadow-white focus:outline-none"
      tabindex="-1"
    >
      <span class="hidden lg:block">{{ $t('general.wholeImage') }}</span>
      <BaseIcon
        name="maximize"
        class="w-5 h-5 text-gray-900 lg:ml-2 sm:w-4 sm:h-4 sm:text-gray-700 transform transition-transform ease-in-out duration-500"
        :class="{ 'icon-active': isFilterMenuActive }"
      />
    </span>
  </button>
</template>

<script>
export default {
  name: 'WholeImageButton'
}
</script>

<style lang="scss" scoped></style>
