export const general = {
  de: {
    iot: {
      beforeBreak: 'Das Internet',
      afterBreak: 'der Dinge'
    },
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    categories: 'Kategorien',
    close: 'Schließen',
    back: 'Zurück',
    open: 'Öffnen',
    wholeImage: 'gesamtes Bild',
    explore: 'Ziehen, Scrollen und Entdecken',
    externalLink: 'Externer Link'
  },
  en: {
    iot: {
      beforeBreak: 'The Internet',
      afterBreak: 'of Things'
    },
    imprint: 'Imprint',
    privacy: 'Privacy',
    categories: 'Categories',
    close: 'Close',
    back: 'Back',
    open: 'Open',
    wholeImage: 'whole image',
    explore: 'Drag, scroll and explore',
    externalLink: 'External link'
  }
}
