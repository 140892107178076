<template>
  <div
    class="h-screen w-screen overflow-hidden pb-12 xl:pb-0 flex justify-center items-center bg-gradient-to-b from-teal-300 to-white"
  >
    <div
      v-if="dataFetched"
      class="w-full h-full relative"
      ref="loadedContent"
      @click="closeTutorial"
    >
      <div
        @wheel="onWheel"
        @panzoomstart="onPanZoomStart"
        @panzoompan="onPanZoomPan"
        @panzoomend="onPanZoomEnd"
        @panzoomzoom="onPanZoomZoom"
        @dblclick="onDblClick"
        @keydown="onKeyDown"
        id="panzoom-element"
        :class="['relative', { 'pointer-events-none': isHotSpotDetailActive }]"
        ref="wimmelbild"
      >
        <picture id="hammer" class="object-cover object-center">
          <source type="image/webp" srcset="wimmelbild-base.webp" />
          <img
            alt=""
            src="wimmelbild-base.jpg"
            class="panzoom-img"
            @load="onLoad"
          />
        </picture>
        <div
          class="hot-spot absolute"
          v-for="(hotSpot, i) in filteredHotSpots"
          v-show="!isHotSpotDetailActive"
          :key="`marker-${i}`"
          :style="
            `left:${hotSpot.markerPositionX}%;top:${
              hotSpot.markerPositionY
            }%;transform: scale(${1 / scale})`
          "
          :data-category="hotSpot.category.id"
        >
          <map-marker
            :hot-spot="hotSpot"
            :is-category-active="activeCategory === hotSpot.category.id"
            :tooltip-placement="hotSpot.tooltipPlacement"
            @open="openHotSpotDetail(hotSpot, $event)"
            @tooltip-pan="
              panzoom.pan(
                $event.x / panzoom.getScale(),
                $event.y / panzoom.getScale(),
                {
                  animate: true,
                  relative: true
                }
              )
            "
          ></map-marker>
        </div>
        <h1
          class="hidden lg:flex flex-col items-start fixed top-0 left-0 m-6 text-4xl font-semibold text-white leading-8"
        >
          <span class="bg-blue-500 pl-4 pr-6 pt-4 pb-4">{{
            $t('general.iot.beforeBreak')
          }}</span>
          <span class="-mt-px bg-blue-500 pl-4 pr-6 pb-4">{{
            $t('general.iot.afterBreak')
          }}</span>
        </h1>
        <div
          :class="[
            'hidden lg:block fixed top-0 right-0 m-4 sm:m-6',
            { 'pointer-events-none': isHotSpotDetailActive }
          ]"
        >
          <a
            href="https://www.barkhauseninstitut.org/"
            target="_blank"
            rel="noopener"
            class="barkhausen-logo"
          >
            <svg
              class="h-14"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 566.7 141.7"
            >
              <path
                d="M56.7,0V28.4h85V0ZM28.4,0H0V141.7H85v-85H28.4ZM56.7,85v28.3H28.4V85Zm56.7,28.3h28.3V56.7H113.4Z"
                fill="#4c8bc6"
              />
              <path
                d="M207.7.2V11.4c0,1.3,0,2.6-.1,3.9s-.2,2.3-.3,3h.4a11.553,11.553,0,0,1,3.7-3.6,10.866,10.866,0,0,1,6.1-1.5,11.424,11.424,0,0,1,9.4,4.5c2.4,3,3.6,7.5,3.6,13.3s-1.2,10.4-3.7,13.4a11.745,11.745,0,0,1-9.6,4.6,11.319,11.319,0,0,1-6-1.4,13.442,13.442,0,0,1-3.6-3.1H207l-1.6,3.8h-7.2V.2Zm6.8,20.5c-2.4,0-4.2.8-5.2,2.3s-1.5,3.9-1.6,6.9v1a17.1,17.1,0,0,0,1.5,7.7q1.5,2.7,5.4,2.7a5.178,5.178,0,0,0,4.6-2.7c1.1-1.8,1.7-4.4,1.7-7.8s-.6-5.9-1.7-7.6A5.272,5.272,0,0,0,214.5,20.7Z"
                fill="#12120d"
              />
              <path
                d="M252.5,13.1c4.7,0,8.2,1,10.7,3s3.7,5.1,3.7,9.2v23h-6.6l-1.8-4.7h-.3a12.144,12.144,0,0,1-4.7,4,14.266,14.266,0,0,1-6.8,1.3,11.03,11.03,0,0,1-7.7-2.7c-2-1.8-3-4.5-3-8.3s1.3-6.4,3.9-8.1,6.4-2.7,11.6-2.9l6-.2V25.2a5.335,5.335,0,0,0-1.4-4,5.688,5.688,0,0,0-4-1.3,16.136,16.136,0,0,0-4.9.7,46.809,46.809,0,0,0-4.8,1.8L239.3,16a34.666,34.666,0,0,1,6.1-2.3A42.533,42.533,0,0,1,252.5,13.1Zm5,19.2-3.7.1c-3,.1-5.1.6-6.3,1.6a5.179,5.179,0,0,0-1.8,4,3.7,3.7,0,0,0,1.3,3.1,5.494,5.494,0,0,0,3.3.9,7.264,7.264,0,0,0,5.1-1.8,6.58,6.58,0,0,0,2.1-5.1Z"
                fill="#12120d"
              />
              <path
                d="M295.9,13.1a9.151,9.151,0,0,1,1.6.1,7.719,7.719,0,0,1,1.5.2l-.7,8.9c-.4-.1-.9-.2-1.3-.3a7.569,7.569,0,0,0-1.5-.1,12.158,12.158,0,0,0-4.6.9,7.562,7.562,0,0,0-3.6,2.8,8.884,8.884,0,0,0-1.4,5.2V48.4h-9.4V13.8h7.1l1.4,5.8h.5a14.208,14.208,0,0,1,4.2-4.6A10.56,10.56,0,0,1,295.9,13.1Z"
                fill="#12120d"
              />
              <path
                d="M314.7.2V21.7a25.487,25.487,0,0,1-.2,3.9c-.2,1.3-.2,2.6-.4,3.9h.1c.6-.9,1.3-1.8,2-2.7s1.4-1.8,2.1-2.6L328,13.7h10.6l-13.7,15,14.6,19.6H328.6l-9.9-14-4,3.2V48.3h-9.4V.2Z"
                fill="#12120d"
              />
              <path
                d="M354,.2V10c0,1.7,0,3.4-.1,4.9s-.2,2.6-.3,3.3h.5a10.5,10.5,0,0,1,4.2-3.9,14.346,14.346,0,0,1,5.8-1.2,12.777,12.777,0,0,1,9,3c2.3,2,3.4,5.2,3.4,9.6V48.3H367V28.1c0-5-1.9-7.5-5.6-7.5-2.8,0-4.8,1-5.9,3s-1.6,4.8-1.6,8.4V48.3h-9.4V.2Z"
                fill="#12120d"
              />
              <path
                d="M400.3,13.1c4.7,0,8.2,1,10.7,3,2.4,2,3.7,5.1,3.7,9.2v23h-6.6l-1.8-4.7H406a13.775,13.775,0,0,1-4.7,4,14.266,14.266,0,0,1-6.8,1.3,11.517,11.517,0,0,1-7.7-2.7c-2-1.8-3-4.5-3-8.3s1.3-6.4,3.9-8.1,6.4-2.7,11.6-2.9l6-.2V25.2a5.335,5.335,0,0,0-1.4-4,5.845,5.845,0,0,0-4-1.3,16.136,16.136,0,0,0-4.9.7,46.809,46.809,0,0,0-4.8,1.8L387.1,16a34.666,34.666,0,0,1,6.1-2.3A41.655,41.655,0,0,1,400.3,13.1Zm5,19.2-3.7.1c-3,.1-5.1.6-6.3,1.6a5.179,5.179,0,0,0-1.8,4,3.7,3.7,0,0,0,1.3,3.1,5.494,5.494,0,0,0,3.3.9,7.264,7.264,0,0,0,5.1-1.8,6.58,6.58,0,0,0,2.1-5.1Z"
                fill="#12120d"
              />
              <path
                d="M456.1,13.8V48.4h-7.2L447.6,44h-.5a10.042,10.042,0,0,1-4.5,3.9,15.8,15.8,0,0,1-6.1,1.2,12.686,12.686,0,0,1-8.9-3c-2.2-2-3.4-5.2-3.4-9.7V13.8h9.4V34a9.883,9.883,0,0,0,1.3,5.6,4.937,4.937,0,0,0,4.2,1.9c2.9,0,4.8-1,5.9-3a19.155,19.155,0,0,0,1.6-8.5V13.8Z"
                fill="#12120d"
              />
              <path
                d="M490.1,38.1a9.5,9.5,0,0,1-3.7,8.1c-2.5,1.9-6.1,2.8-11,2.8a31.781,31.781,0,0,1-6.2-.5A23.772,23.772,0,0,1,464,47V39.2a30.446,30.446,0,0,0,6,2.1,22.394,22.394,0,0,0,5.7.8,7.414,7.414,0,0,0,3.9-.8,2.509,2.509,0,0,0,1.2-2.2,3.022,3.022,0,0,0-.5-1.6,6.524,6.524,0,0,0-2.1-1.6c-1.6-.8-3.3-1.6-4.9-2.3a52.785,52.785,0,0,1-5.3-2.7,8.958,8.958,0,0,1-3.1-3.3,10.274,10.274,0,0,1-1-4.9,7.952,7.952,0,0,1,3.7-7.2q3.75-2.4,9.9-2.4a26.463,26.463,0,0,1,12.1,2.7l-2.9,6.8q-2.55-1.05-4.8-1.8a14.69,14.69,0,0,0-4.6-.7c-2.8,0-4.2.8-4.2,2.3a2.7,2.7,0,0,0,.5,1.5,5.957,5.957,0,0,0,2.1,1.4c1,.5,2.6,1.2,4.6,2a30.153,30.153,0,0,1,5.1,2.5,8.715,8.715,0,0,1,3.3,3.3A7.5,7.5,0,0,1,490.1,38.1Z"
                fill="#12120d"
              />
              <path
                d="M511.7,13.1c4.8,0,8.5,1.4,11.3,4.1s4.2,6.6,4.2,11.6v4.6H504.9a9.186,9.186,0,0,0,2.4,6.3,8.425,8.425,0,0,0,6.2,2.3,24.584,24.584,0,0,0,6-.7,27.755,27.755,0,0,0,5.7-2.1v7.3a24.7,24.7,0,0,1-5.4,1.9,38.217,38.217,0,0,1-6.8.6,20.183,20.183,0,0,1-9.1-2,15.321,15.321,0,0,1-6.3-5.8,20.044,20.044,0,0,1-2.3-9.9,22.1,22.1,0,0,1,2.1-10.1,13.464,13.464,0,0,1,5.7-6.1A18.142,18.142,0,0,1,511.7,13.1Zm0,6.8a6.249,6.249,0,0,0-4.6,1.8,8.556,8.556,0,0,0-2.1,5.5h13.2a8.747,8.747,0,0,0-1.7-5.2A5.224,5.224,0,0,0,511.7,19.9Z"
                fill="#12120d"
              />
              <path
                d="M554.5,13.1a12.686,12.686,0,0,1,8.9,3c2.2,2,3.4,5.2,3.3,9.6V48.3h-9.4V28.1a9.883,9.883,0,0,0-1.3-5.6,4.937,4.937,0,0,0-4.2-1.9c-2.9,0-4.8,1-5.9,3s-1.6,4.8-1.6,8.5V48.4h-9.4V13.8h7.2l1.3,4.4h.5a10.042,10.042,0,0,1,4.5-3.9A15.8,15.8,0,0,1,554.5,13.1Z"
                fill="#12120d"
              />
              <path
                d="M201.6,66.7a3.523,3.523,0,0,1,2.3.9,4.226,4.226,0,0,1,0,5.4,3.619,3.619,0,0,1-4.6,0,4.226,4.226,0,0,1,0-5.4A3.168,3.168,0,0,1,201.6,66.7Zm2.7,12.8v33.9h-5.6V79.5Z"
                fill="#12120d"
              />
              <path
                d="M231.4,78.8c4.1,0,7.1,1,9.2,3s3.1,5.2,3.1,9.5v22.1h-5.5V91.7c0-5.4-2.5-8.2-7.6-8.2-3.8,0-6.4,1-7.8,3.2a16.749,16.749,0,0,0-2.2,9.1v17.6H215V79.5h4.5l.8,4.6h.3a9.92,9.92,0,0,1,4.6-4A14.369,14.369,0,0,1,231.4,78.8Z"
                fill="#12120d"
              />
              <path
                d="M276.3,104a8.342,8.342,0,0,1-3.7,7.5c-2.5,1.7-5.7,2.5-9.9,2.5a30.779,30.779,0,0,1-6.1-.6,17.211,17.211,0,0,1-4.5-1.6v-5a29.207,29.207,0,0,0,4.9,1.9,16.844,16.844,0,0,0,5.9.8c2.8,0,4.9-.5,6.1-1.4a4.369,4.369,0,0,0,1.9-3.6,3.891,3.891,0,0,0-.7-2.3,8.181,8.181,0,0,0-2.5-2,48.29,48.29,0,0,0-5.2-2.3,56.161,56.161,0,0,1-5.6-2.5,10.342,10.342,0,0,1-3.6-3,7.681,7.681,0,0,1-1.3-4.6,7.328,7.328,0,0,1,3.5-6.6,15.813,15.813,0,0,1,9.2-2.3,28.558,28.558,0,0,1,5.8.6,29.112,29.112,0,0,1,5,1.6l-1.9,4.4a39.56,39.56,0,0,0-4.5-1.5,19.725,19.725,0,0,0-4.8-.6,10.387,10.387,0,0,0-5.2,1.1,3.375,3.375,0,0,0-1.8,3,4.069,4.069,0,0,0,.8,2.4,9.235,9.235,0,0,0,2.8,1.9c1.3.6,3,1.3,5.2,2.2a38.745,38.745,0,0,1,5.5,2.5,9.48,9.48,0,0,1,3.5,3.1A8.063,8.063,0,0,1,276.3,104Z"
                fill="#12120d"
              />
              <path
                d="M295.8,109.5a22.752,22.752,0,0,0,2.6-.2,12.911,12.911,0,0,0,2.1-.5V113a7.827,7.827,0,0,1-2.5.7,16.551,16.551,0,0,1-3.2.3,13.717,13.717,0,0,1-4.9-.9,7.474,7.474,0,0,1-3.6-3.2,13.014,13.014,0,0,1-1.4-6.4V83.8h-4.8V81.1l4.9-2.2,2.2-7.2h3.3v7.8h9.8v4.3h-9.8v19.6A6.406,6.406,0,0,0,292,108,5.367,5.367,0,0,0,295.8,109.5Z"
                fill="#12120d"
              />
              <path
                d="M310.2,66.7a3.523,3.523,0,0,1,2.3.9,4.226,4.226,0,0,1,0,5.4,3.619,3.619,0,0,1-4.6,0,4.226,4.226,0,0,1,0-5.4A3.168,3.168,0,0,1,310.2,66.7Zm2.7,12.8v33.9h-5.6V79.5Z"
                fill="#12120d"
              />
              <path
                d="M335,109.5a22.752,22.752,0,0,0,2.6-.2,12.911,12.911,0,0,0,2.1-.5V113a7.827,7.827,0,0,1-2.5.7,16.551,16.551,0,0,1-3.2.3,13.717,13.717,0,0,1-4.9-.9,7.474,7.474,0,0,1-3.6-3.2,13.014,13.014,0,0,1-1.4-6.4V83.8h-4.8V81.1l4.9-2.2,2.2-7.2h3.3v7.8h9.8v4.3h-9.8v19.6a6.406,6.406,0,0,0,1.5,4.6A5.367,5.367,0,0,0,335,109.5Z"
                fill="#12120d"
              />
              <path
                d="M374.9,79.5v33.9h-4.5l-.8-4.5h-.3a10.3,10.3,0,0,1-4.6,3.9,14.9,14.9,0,0,1-6.2,1.2c-4.1,0-7.2-1-9.2-2.9-2.1-2-3.1-5.1-3.1-9.4V79.5h5.6v21.8q0,8.1,7.5,8.1c3.8,0,6.4-1.1,7.8-3.2s2.2-5.1,2.2-9.1V79.5Z"
                fill="#12120d"
              />
              <path
                d="M397,109.5a22.752,22.752,0,0,0,2.6-.2,12.911,12.911,0,0,0,2.1-.5V113a7.827,7.827,0,0,1-2.5.7,17.329,17.329,0,0,1-3.2.3,13.717,13.717,0,0,1-4.9-.9,7.474,7.474,0,0,1-3.6-3.2,13.014,13.014,0,0,1-1.4-6.4V83.8h-4.8V81.1l4.9-2.2,2.2-7.2h3.3v7.8h9.8v4.3h-9.8v19.6a6.406,6.406,0,0,0,1.5,4.6A5.2,5.2,0,0,0,397,109.5Z"
                fill="#12120d"
              />
            </svg>
          </a>
        </div>
      </div>
      <div>
        <h1
          class="lg:hidden flex flex-col items-start fixed top-0 left-0 m-4 sm:m-6 text-xl sm:text-3xl font-semibold text-white leading-6 sm:leading-8"
        >
          <span
            class="bg-blue-500 pl-2 pr-2 pt-1 pb-1 sm:pl-3 sm:pr-5 sm:pt-2 sm:pb-3"
            >{{ $t('general.iot.beforeBreak') }}</span
          >
          <span
            class="bg-blue-500 pl-2 pr-2 pt-0 pb-1 sm:pl-3 sm:pr-5 sm:pb-3"
            >{{ $t('general.iot.afterBreak') }}</span
          >
        </h1>
        <div
          :class="[
            'lg:hidden fixed top-0 right-0 m-4 sm:m-6',
            { 'pointer-events-none': isHotSpotDetailActive }
          ]"
        >
          <a
            href="https://www.barkhauseninstitut.org/"
            target="_blank"
            rel="noopener"
            class="barkhausen-logo"
          >
            <svg
              class="h-8 sm:h-10 lg:h-12"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 566.7 141.7"
            >
              <path
                d="M56.7,0V28.4h85V0ZM28.4,0H0V141.7H85v-85H28.4ZM56.7,85v28.3H28.4V85Zm56.7,28.3h28.3V56.7H113.4Z"
                fill="#4c8bc6"
              />
              <path
                d="M207.7.2V11.4c0,1.3,0,2.6-.1,3.9s-.2,2.3-.3,3h.4a11.553,11.553,0,0,1,3.7-3.6,10.866,10.866,0,0,1,6.1-1.5,11.424,11.424,0,0,1,9.4,4.5c2.4,3,3.6,7.5,3.6,13.3s-1.2,10.4-3.7,13.4a11.745,11.745,0,0,1-9.6,4.6,11.319,11.319,0,0,1-6-1.4,13.442,13.442,0,0,1-3.6-3.1H207l-1.6,3.8h-7.2V.2Zm6.8,20.5c-2.4,0-4.2.8-5.2,2.3s-1.5,3.9-1.6,6.9v1a17.1,17.1,0,0,0,1.5,7.7q1.5,2.7,5.4,2.7a5.178,5.178,0,0,0,4.6-2.7c1.1-1.8,1.7-4.4,1.7-7.8s-.6-5.9-1.7-7.6A5.272,5.272,0,0,0,214.5,20.7Z"
                fill="#12120d"
              />
              <path
                d="M252.5,13.1c4.7,0,8.2,1,10.7,3s3.7,5.1,3.7,9.2v23h-6.6l-1.8-4.7h-.3a12.144,12.144,0,0,1-4.7,4,14.266,14.266,0,0,1-6.8,1.3,11.03,11.03,0,0,1-7.7-2.7c-2-1.8-3-4.5-3-8.3s1.3-6.4,3.9-8.1,6.4-2.7,11.6-2.9l6-.2V25.2a5.335,5.335,0,0,0-1.4-4,5.688,5.688,0,0,0-4-1.3,16.136,16.136,0,0,0-4.9.7,46.809,46.809,0,0,0-4.8,1.8L239.3,16a34.666,34.666,0,0,1,6.1-2.3A42.533,42.533,0,0,1,252.5,13.1Zm5,19.2-3.7.1c-3,.1-5.1.6-6.3,1.6a5.179,5.179,0,0,0-1.8,4,3.7,3.7,0,0,0,1.3,3.1,5.494,5.494,0,0,0,3.3.9,7.264,7.264,0,0,0,5.1-1.8,6.58,6.58,0,0,0,2.1-5.1Z"
                fill="#12120d"
              />
              <path
                d="M295.9,13.1a9.151,9.151,0,0,1,1.6.1,7.719,7.719,0,0,1,1.5.2l-.7,8.9c-.4-.1-.9-.2-1.3-.3a7.569,7.569,0,0,0-1.5-.1,12.158,12.158,0,0,0-4.6.9,7.562,7.562,0,0,0-3.6,2.8,8.884,8.884,0,0,0-1.4,5.2V48.4h-9.4V13.8h7.1l1.4,5.8h.5a14.208,14.208,0,0,1,4.2-4.6A10.56,10.56,0,0,1,295.9,13.1Z"
                fill="#12120d"
              />
              <path
                d="M314.7.2V21.7a25.487,25.487,0,0,1-.2,3.9c-.2,1.3-.2,2.6-.4,3.9h.1c.6-.9,1.3-1.8,2-2.7s1.4-1.8,2.1-2.6L328,13.7h10.6l-13.7,15,14.6,19.6H328.6l-9.9-14-4,3.2V48.3h-9.4V.2Z"
                fill="#12120d"
              />
              <path
                d="M354,.2V10c0,1.7,0,3.4-.1,4.9s-.2,2.6-.3,3.3h.5a10.5,10.5,0,0,1,4.2-3.9,14.346,14.346,0,0,1,5.8-1.2,12.777,12.777,0,0,1,9,3c2.3,2,3.4,5.2,3.4,9.6V48.3H367V28.1c0-5-1.9-7.5-5.6-7.5-2.8,0-4.8,1-5.9,3s-1.6,4.8-1.6,8.4V48.3h-9.4V.2Z"
                fill="#12120d"
              />
              <path
                d="M400.3,13.1c4.7,0,8.2,1,10.7,3,2.4,2,3.7,5.1,3.7,9.2v23h-6.6l-1.8-4.7H406a13.775,13.775,0,0,1-4.7,4,14.266,14.266,0,0,1-6.8,1.3,11.517,11.517,0,0,1-7.7-2.7c-2-1.8-3-4.5-3-8.3s1.3-6.4,3.9-8.1,6.4-2.7,11.6-2.9l6-.2V25.2a5.335,5.335,0,0,0-1.4-4,5.845,5.845,0,0,0-4-1.3,16.136,16.136,0,0,0-4.9.7,46.809,46.809,0,0,0-4.8,1.8L387.1,16a34.666,34.666,0,0,1,6.1-2.3A41.655,41.655,0,0,1,400.3,13.1Zm5,19.2-3.7.1c-3,.1-5.1.6-6.3,1.6a5.179,5.179,0,0,0-1.8,4,3.7,3.7,0,0,0,1.3,3.1,5.494,5.494,0,0,0,3.3.9,7.264,7.264,0,0,0,5.1-1.8,6.58,6.58,0,0,0,2.1-5.1Z"
                fill="#12120d"
              />
              <path
                d="M456.1,13.8V48.4h-7.2L447.6,44h-.5a10.042,10.042,0,0,1-4.5,3.9,15.8,15.8,0,0,1-6.1,1.2,12.686,12.686,0,0,1-8.9-3c-2.2-2-3.4-5.2-3.4-9.7V13.8h9.4V34a9.883,9.883,0,0,0,1.3,5.6,4.937,4.937,0,0,0,4.2,1.9c2.9,0,4.8-1,5.9-3a19.155,19.155,0,0,0,1.6-8.5V13.8Z"
                fill="#12120d"
              />
              <path
                d="M490.1,38.1a9.5,9.5,0,0,1-3.7,8.1c-2.5,1.9-6.1,2.8-11,2.8a31.781,31.781,0,0,1-6.2-.5A23.772,23.772,0,0,1,464,47V39.2a30.446,30.446,0,0,0,6,2.1,22.394,22.394,0,0,0,5.7.8,7.414,7.414,0,0,0,3.9-.8,2.509,2.509,0,0,0,1.2-2.2,3.022,3.022,0,0,0-.5-1.6,6.524,6.524,0,0,0-2.1-1.6c-1.6-.8-3.3-1.6-4.9-2.3a52.785,52.785,0,0,1-5.3-2.7,8.958,8.958,0,0,1-3.1-3.3,10.274,10.274,0,0,1-1-4.9,7.952,7.952,0,0,1,3.7-7.2q3.75-2.4,9.9-2.4a26.463,26.463,0,0,1,12.1,2.7l-2.9,6.8q-2.55-1.05-4.8-1.8a14.69,14.69,0,0,0-4.6-.7c-2.8,0-4.2.8-4.2,2.3a2.7,2.7,0,0,0,.5,1.5,5.957,5.957,0,0,0,2.1,1.4c1,.5,2.6,1.2,4.6,2a30.153,30.153,0,0,1,5.1,2.5,8.715,8.715,0,0,1,3.3,3.3A7.5,7.5,0,0,1,490.1,38.1Z"
                fill="#12120d"
              />
              <path
                d="M511.7,13.1c4.8,0,8.5,1.4,11.3,4.1s4.2,6.6,4.2,11.6v4.6H504.9a9.186,9.186,0,0,0,2.4,6.3,8.425,8.425,0,0,0,6.2,2.3,24.584,24.584,0,0,0,6-.7,27.755,27.755,0,0,0,5.7-2.1v7.3a24.7,24.7,0,0,1-5.4,1.9,38.217,38.217,0,0,1-6.8.6,20.183,20.183,0,0,1-9.1-2,15.321,15.321,0,0,1-6.3-5.8,20.044,20.044,0,0,1-2.3-9.9,22.1,22.1,0,0,1,2.1-10.1,13.464,13.464,0,0,1,5.7-6.1A18.142,18.142,0,0,1,511.7,13.1Zm0,6.8a6.249,6.249,0,0,0-4.6,1.8,8.556,8.556,0,0,0-2.1,5.5h13.2a8.747,8.747,0,0,0-1.7-5.2A5.224,5.224,0,0,0,511.7,19.9Z"
                fill="#12120d"
              />
              <path
                d="M554.5,13.1a12.686,12.686,0,0,1,8.9,3c2.2,2,3.4,5.2,3.3,9.6V48.3h-9.4V28.1a9.883,9.883,0,0,0-1.3-5.6,4.937,4.937,0,0,0-4.2-1.9c-2.9,0-4.8,1-5.9,3s-1.6,4.8-1.6,8.5V48.4h-9.4V13.8h7.2l1.3,4.4h.5a10.042,10.042,0,0,1,4.5-3.9A15.8,15.8,0,0,1,554.5,13.1Z"
                fill="#12120d"
              />
              <path
                d="M201.6,66.7a3.523,3.523,0,0,1,2.3.9,4.226,4.226,0,0,1,0,5.4,3.619,3.619,0,0,1-4.6,0,4.226,4.226,0,0,1,0-5.4A3.168,3.168,0,0,1,201.6,66.7Zm2.7,12.8v33.9h-5.6V79.5Z"
                fill="#12120d"
              />
              <path
                d="M231.4,78.8c4.1,0,7.1,1,9.2,3s3.1,5.2,3.1,9.5v22.1h-5.5V91.7c0-5.4-2.5-8.2-7.6-8.2-3.8,0-6.4,1-7.8,3.2a16.749,16.749,0,0,0-2.2,9.1v17.6H215V79.5h4.5l.8,4.6h.3a9.92,9.92,0,0,1,4.6-4A14.369,14.369,0,0,1,231.4,78.8Z"
                fill="#12120d"
              />
              <path
                d="M276.3,104a8.342,8.342,0,0,1-3.7,7.5c-2.5,1.7-5.7,2.5-9.9,2.5a30.779,30.779,0,0,1-6.1-.6,17.211,17.211,0,0,1-4.5-1.6v-5a29.207,29.207,0,0,0,4.9,1.9,16.844,16.844,0,0,0,5.9.8c2.8,0,4.9-.5,6.1-1.4a4.369,4.369,0,0,0,1.9-3.6,3.891,3.891,0,0,0-.7-2.3,8.181,8.181,0,0,0-2.5-2,48.29,48.29,0,0,0-5.2-2.3,56.161,56.161,0,0,1-5.6-2.5,10.342,10.342,0,0,1-3.6-3,7.681,7.681,0,0,1-1.3-4.6,7.328,7.328,0,0,1,3.5-6.6,15.813,15.813,0,0,1,9.2-2.3,28.558,28.558,0,0,1,5.8.6,29.112,29.112,0,0,1,5,1.6l-1.9,4.4a39.56,39.56,0,0,0-4.5-1.5,19.725,19.725,0,0,0-4.8-.6,10.387,10.387,0,0,0-5.2,1.1,3.375,3.375,0,0,0-1.8,3,4.069,4.069,0,0,0,.8,2.4,9.235,9.235,0,0,0,2.8,1.9c1.3.6,3,1.3,5.2,2.2a38.745,38.745,0,0,1,5.5,2.5,9.48,9.48,0,0,1,3.5,3.1A8.063,8.063,0,0,1,276.3,104Z"
                fill="#12120d"
              />
              <path
                d="M295.8,109.5a22.752,22.752,0,0,0,2.6-.2,12.911,12.911,0,0,0,2.1-.5V113a7.827,7.827,0,0,1-2.5.7,16.551,16.551,0,0,1-3.2.3,13.717,13.717,0,0,1-4.9-.9,7.474,7.474,0,0,1-3.6-3.2,13.014,13.014,0,0,1-1.4-6.4V83.8h-4.8V81.1l4.9-2.2,2.2-7.2h3.3v7.8h9.8v4.3h-9.8v19.6A6.406,6.406,0,0,0,292,108,5.367,5.367,0,0,0,295.8,109.5Z"
                fill="#12120d"
              />
              <path
                d="M310.2,66.7a3.523,3.523,0,0,1,2.3.9,4.226,4.226,0,0,1,0,5.4,3.619,3.619,0,0,1-4.6,0,4.226,4.226,0,0,1,0-5.4A3.168,3.168,0,0,1,310.2,66.7Zm2.7,12.8v33.9h-5.6V79.5Z"
                fill="#12120d"
              />
              <path
                d="M335,109.5a22.752,22.752,0,0,0,2.6-.2,12.911,12.911,0,0,0,2.1-.5V113a7.827,7.827,0,0,1-2.5.7,16.551,16.551,0,0,1-3.2.3,13.717,13.717,0,0,1-4.9-.9,7.474,7.474,0,0,1-3.6-3.2,13.014,13.014,0,0,1-1.4-6.4V83.8h-4.8V81.1l4.9-2.2,2.2-7.2h3.3v7.8h9.8v4.3h-9.8v19.6a6.406,6.406,0,0,0,1.5,4.6A5.367,5.367,0,0,0,335,109.5Z"
                fill="#12120d"
              />
              <path
                d="M374.9,79.5v33.9h-4.5l-.8-4.5h-.3a10.3,10.3,0,0,1-4.6,3.9,14.9,14.9,0,0,1-6.2,1.2c-4.1,0-7.2-1-9.2-2.9-2.1-2-3.1-5.1-3.1-9.4V79.5h5.6v21.8q0,8.1,7.5,8.1c3.8,0,6.4-1.1,7.8-3.2s2.2-5.1,2.2-9.1V79.5Z"
                fill="#12120d"
              />
              <path
                d="M397,109.5a22.752,22.752,0,0,0,2.6-.2,12.911,12.911,0,0,0,2.1-.5V113a7.827,7.827,0,0,1-2.5.7,17.329,17.329,0,0,1-3.2.3,13.717,13.717,0,0,1-4.9-.9,7.474,7.474,0,0,1-3.6-3.2,13.014,13.014,0,0,1-1.4-6.4V83.8h-4.8V81.1l4.9-2.2,2.2-7.2h3.3v7.8h9.8v4.3h-9.8v19.6a6.406,6.406,0,0,0,1.5,4.6A5.2,5.2,0,0,0,397,109.5Z"
                fill="#12120d"
              />
            </svg>
          </a>
        </div>
        <div
          :class="[
            'hidden fixed bottom-0 right-0 m-6 xl:flex flex-col items-end space-y-4 transition ease-in-out duration-500 transform',
            {
              '-translate-y-menu': isFilterMenuActive,
              'pointer-events-none': isHotSpotDetailActive
            }
          ]"
        >
          <div class="flex items-center space-x-8">
            <LanguageSwitch />
            <FilterButton
              @click="toggleFilterMenu"
              :is-filter-menu-active="isFilterMenuActive"
            />
          </div>
        </div>
        <div
          class="hidden sm:block fixed bottom-0 right-0 mr-4 mb-4 xl:mb-20 sm:mr-6 transition ease-in-out duration-500 transform"
          :class="{
            '-translate-y-menu': isFilterMenuActive,
            'pointer-events-none': isHotSpotDetailActive
          }"
        >
          <div class="flex flex-col space-y-1">
            <ZoomButton zoom-action="in" @click="zoom('in')" />
            <ZoomButton zoom-action="out" @click="zoom('out')" />
          </div>
        </div>
        <div
          class="fixed bottom-0 left-0 flex flex-col sm:items-center sm:flex-row ml-4 mb-16 xl:m-6 text-white text-xs transition ease-in-out duration-500 transform"
          :class="{ 'xl:-translate-y-menu': isFilterMenuActive }"
        >
          <ul class="flex items-center justify-between w-full sm:w-auto">
            <li class="hidden sm:block">
              <WholeImageButton @click="isWholeImageActive = true" />
            </li>
            <li class="ml-0 sm:ml-8">
              <button
                class="uppercase tracking-wide transition duration-150 ease-in-out font-medium hover:text-gray-300 text-shadow"
                @click="isOverlayImprintActive = true"
              >
                <span tabindex="-1" class="focus:outline-none">
                  {{ $t('general.imprint') }}
                </span>
              </button>
            </li>
            <li class="ml-4 sm:ml-8">
              <button
                class="uppercase tracking-wide transition duration-150 ease-in-out font-medium hover:text-gray-300 text-shadow"
                @click="isOverlayPrivacyActive = true"
              >
                <span tabindex="-1" class="focus:outline-none">
                  {{ $t('general.privacy') }}
                </span>
              </button>
            </li>
          </ul>
          <span class="mt-1 sm:mt-0 sm:ml-8 text-shadow italic">
            Illustration: Robert Richter
          </span>
        </div>
        <div class="sm:hidden fixed right-0 mb-16 bottom-0 mr-4">
          <WholeImageButton @click="isWholeImageActive = true" />
        </div>
      </div>
      <div class="tutorial-element-wrapper">
        <InitialTutorialElement />
      </div>
      <filter-menu
        :class="[
          'hidden xl:block shadow-custom-sm transition duration-500 ease-in-out transform',
          {
            'translate-y-full': !isFilterMenuActive,
            'pointer-events-none': isHotSpotDetailActive
          }
        ]"
        @close="isFilterMenuActive = false"
      >
        <template #items>
          <FilterMenuItem
            v-for="category in categories"
            :key="category"
            :category="category"
            :is-active="activeCategory === category.id"
            @itemclick="onCategoryFilterClick"
            @mouseover="hoveredCategory = category.id"
            @mouseleave="hoveredCategory = -1"
          />
        </template>
      </filter-menu>
      <div
        :class="[
          'xl:hidden h-12 fixed bottom-0 inset-x-0 flex items-center',
          { 'pointer-events-none': isHotSpotDetailActive }
        ]"
      >
        <MobileLanguageSwitcher />
        <mobile-filter-menu
          :categories="categories"
          :active-category="activeCategory"
          @categoryclick="onCategoryFilterClick"
        >
        </mobile-filter-menu>
      </div>
      <div
        v-if="isHotSpotDetailActive"
        class="fixed inset-0 bg-transparent"
        @click="onHotSpotDetailReset"
      ></div>
      <transition
        enter-active-class="transition duration-500 ease-in-out transform"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition duration-200 ease-in-out transform"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <HotSpotDetail
          v-if="isHotSpotDetailActive"
          :hot-spot="activeHotSpot"
          @reset="onHotSpotDetailReset"
          @close="onHotSpotDetailReset"
        />
      </transition>
      <transition
        enter-active-class="transition duration-500 ease-in-out "
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-500 ease-in-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <content-overlay
          v-if="isOverlayImprintActive"
          @close="isOverlayImprintActive = false"
        >
          <Imprint />
        </content-overlay>
      </transition>
      <transition
        enter-active-class="transition duration-500 ease-in-out "
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-500 ease-in-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <content-overlay
          v-if="isOverlayPrivacyActive"
          @close="isOverlayPrivacyActive = false"
        >
          <Privacy />
        </content-overlay>
      </transition>
      <transition
        enter-active-class="transition duration-500 ease-in-out "
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-500 ease-in-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <WholeImage
          v-if="isWholeImageActive"
          @close="isWholeImageActive = false"
        />
      </transition>
    </div>
    <transition
      leave-active-class="transition duration-700 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <LoadingScreen
        v-if="isLoadingScreenActive"
        :is-loading="loading"
        @close="closeLoadingScreen"
      />
    </transition>
  </div>
</template>

<script>
import Panzoom from '@panzoom/panzoom'
import Hammer from 'hammerjs'

import InitialTutorialElement from './hud-elements/InitialTutorialElement'
import MapMarker from './hud-elements/MapMarker'
import LoadingScreen from './LoadingScreen'
import LanguageSwitch from './hud-elements/LanguageSwitch'
import FilterButton from './hud-elements/FilterButton'
import FilterMenu from './hud-elements/filter-menu/FilterMenu'
import FilterMenuItem from './hud-elements/filter-menu/FilterMenuItem'
import HotSpotDetail from './hud-elements/HotSpotDetail'
import MobileLanguageSwitcher from './hud-elements/mobile/MobileLanguageSwitcher'
import MobileFilterMenu from './hud-elements/mobile/MobileFilterMenu'
import ZoomButton from './hud-elements/ZoomButton'
import ContentOverlay from './ContentOverlay'
import Imprint from './Imprint'
import Privacy from './Privacy'
import WholeImageButton from './hud-elements/WholeImageButton'
import WholeImage from './hud-elements/WholeImage'

export default {
  name: 'InteractiveGraphic',
  components: {
    WholeImage,
    WholeImageButton,
    Privacy,
    Imprint,
    ContentOverlay,
    ZoomButton,
    MobileFilterMenu,
    MobileLanguageSwitcher,
    HotSpotDetail,
    FilterMenuItem,
    FilterMenu,
    FilterButton,
    LanguageSwitch,
    LoadingScreen,
    MapMarker,
    InitialTutorialElement
  },
  computed: {
    categories() {
      return this.$store.state.categories
    },
    hotSpots() {
      return this.$store.state.hotSpots
    },
    filteredHotSpots() {
      return this.hotSpots
    },
    scale() {
      return this.$store.state.scale
    }
  },
  data() {
    return {
      panzoom: null,
      loading: true,
      isLoadingScreenActive: true,
      isFilterMenuActive: true,
      activeCategory: -1,
      isHotSpotDetailActive: false,
      activeHotSpot: null,
      hoveredCategory: -1,
      dataFetched: false,
      everythingLoaded: false,
      isOverlayPrivacyActive: false,
      isOverlayImprintActive: false,
      isWholeImageActive: false,
      xxxxx: ''
    }
  },
  async created() {
    await this.$store.dispatch('fetchHotSpots')
    this.dataFetched = true
    this.loading = true
    this.everythingLoaded = true
  },
  mounted() {
    this.isLoadingScreenActive = true
    this.isFilterMenuActive = true
  },
  methods: {
    onCategoryFilterClick(id) {
      this.activeCategory = this.activeCategory === id ? -1 : id

      if (this.activeCategory !== -1) {
        const markers = document.querySelectorAll('.hot-spot')
        const markersArray = Array.prototype.slice.call(markers)
        const markersArrayCategory = markersArray.filter(
          marker => Number(marker.dataset.category) === Number(id)
        )
        const markersOutOfBound = markersArrayCategory.filter(
          marker => this.markerOutOfBound(marker).isOutOfBounds
        )
        if (markersOutOfBound.length === markersArrayCategory.length) {
          const overflow = this.markerOutOfBound(markersOutOfBound[0]).overflow
          this.panzoom.pan(
            overflow.x ? overflow.x / this.panzoom.getScale() : 0,
            overflow.y ? overflow.y / this.panzoom.getScale() : 0,
            { relative: true, animate: true, duration: 500 }
          )
        }
      }
    },
    onWheel(event) {
      this.panzoom.zoomWithWheel(event, { force: true })
    },
    onPanZoomStart() {
      this.closeTutorial()
    },
    onPanZoomPan() {
      this.panzoom.setStyle('cursor', 'move')
      this.setMarkerTabIndexes()
    },
    onPanZoomEnd() {
      this.panzoom.setStyle('cursor', 'default')
      this.setMarkerTabIndexes()
    },
    onPanZoomZoom(event) {
      this.$store.commit(
        'setScale',
        event.detail.scale / Math.pow(event.detail.scale, 0.15)
      )
      this.setMarkerTabIndexes()
    },
    onDblClick(event) {
      const scale = this.panzoom.getScale() === 4 ? 1 : 4
      this.panzoom.zoomToPoint(
        scale,
        { clientX: event.clientX, clientY: event.clientY },
        { animate: true, duration: 500 }
      )
    },
    onLoad() {
      if (this.dataFetched)
        setTimeout(() => {
          this.loading = false
          this.everythingLoaded = true
        }, 250)
    },
    closeLoadingScreen() {
      this.isLoadingScreenActive = false
      const elem = document.getElementById('panzoom-element')
      this.panzoom = Panzoom(elem, {
        contain: 'outside',
        startScale: 1,
        startX: -this.$refs.wimmelbild.scrollWidth / 4,
        maxScale: 4,
        minScale: 1,
        duration: 250,
        excludeClass: 'hot-spot',
        cursor: 'default',
        step: 0.5,
        beforeWheel: function(e) {
          return !e.altKey
        }
      })

      const hammer = new Hammer(document.getElementById('hammer'))
      hammer.get('pinch').set({ enable: true })

      hammer.on('pinchstart', () => {
        this.panzoom.setOptions({ disablePan: true })
      })
      hammer.on('pinchend', () => {
        this.panzoom.setOptions({ disablePan: false })
      })

      window.addEventListener('keydown', this.onKeyDown)

      window.onresize = this.setMarkerTabIndexes
      this.setMarkerTabIndexes()
    },
    closeTutorial() {
      this.$store.state.tutorialActive = false
    },
    openHotSpotDetail(hotSpot, event) {
      this.closeTutorial()
      this.activeHotSpot = hotSpot
      this.isHotSpotDetailActive = true

      const targetXFactor =
        window.innerWidth > 1050 ? 0.618 : window.innerWidth >= 776 ? 0.85 : 0.5

      const targetX = window.innerWidth * targetXFactor
      const targetY = window.innerHeight * 0.5

      const panAmountX = targetX - event.left
      const panAmountY = targetY - event.top

      const animationDuration = 800 / this.panzoom.getScale()

      if (this.panzoom.getScale() === 4) {
        this.panzoom.pan(panAmountX / 4, panAmountY / 4, {
          relative: true,
          animate: true
        })
      } else {
        this.panzoom.zoomToPoint(
          4,
          {
            clientX:
              event.left - (targetX - event.left) * this.getZoomToPointFactor(),
            clientY:
              event.top - (targetY - event.top) * this.getZoomToPointFactor()
          },
          { animate: true, duration: animationDuration }
        )
      }
    },
    getZoomToPointFactor() {
      return (
        (2 * Math.pow(this.panzoom.getScale(), 2)) / 3 -
        (4 * this.panzoom.getScale()) / 3 +
        1
      )
    },
    closeHotSpotDetail() {
      this.isHotSpotDetailActive = false
      this.activeHotSpot = null
      this.panzoom.setOptions({ disablePan: false, disableZoom: false })
    },
    onHotSpotDetailReset() {
      this.closeHotSpotDetail()
      this.panzoom.zoomToPoint(
        1,
        { clientX: window.innerWidth / 2, clientY: window.innerHeight / 2 },
        { animate: true, duration: 500 }
      )
    },
    toggleFilterMenu() {
      this.isFilterMenuActive = !this.isFilterMenuActive
    },
    zoom(mode) {
      if (mode === 'in') this.panzoom.zoomIn({ force: true })
      else this.panzoom.zoomOut({ force: true })
    },
    onKeyDown(event) {
      if (
        ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(event.key)
      ) {
        const panningSpeed = 100 / this.panzoom.getScale()

        const panAmountX =
          event.key === 'ArrowLeft'
            ? panningSpeed
            : event.key === 'ArrowRight'
            ? -panningSpeed
            : 0

        const panAmountY =
          event.key === 'ArrowUp'
            ? panningSpeed
            : event.key === 'ArrowDown'
            ? -panningSpeed
            : 0

        this.panzoom.pan(panAmountX, panAmountY, {
          relative: true,
          animate: true
        })
      }

      if (['+', '-'].includes(event.key))
        this.zoom(event.key === '+' ? 'in' : 'out')
    },
    setMarkerTabIndexes() {
      const hostSpots = document.querySelectorAll('.hot-spot')
      hostSpots.forEach(hostSpot => {
        const marker = hostSpot.querySelector('.map-marker-wrapper')
        if (marker.getBoundingClientRect().y > window.innerHeight - 60) {
          marker.tabIndex = -1
        } else marker.tabIndex = 0
      })
    },
    markerOutOfBound(marker) {
      const clientRect = marker.getBoundingClientRect()

      const overflow = {}

      if (clientRect.x < 0) overflow.x = window.innerWidth * 0.5 - clientRect.x
      if (clientRect.x > window.innerWidth)
        overflow.x = window.innerWidth * 0.5 - clientRect.x

      if (clientRect.y < 0) overflow.y = window.innerHeight * 0.5 - clientRect.y
      if (clientRect.y > window.innerHeight - 60)
        overflow.y = window.innerHeight * 0.5 - clientRect.y

      return {
        isOutOfBounds: overflow.x || overflow.y,
        overflow
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#panzoom-element {
  /*min-width: 100vh;*/
  min-width: calc(100vh * 6005 / 4717);
}

.panzoom-img {
  min-height: 100vh;
  min-width: calc(100vh * 6005 / 4717);
  width: 100%;
  object-fit: cover;
}

.tutorial-element-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: initial !important;
  -webkit-transform-origin: 50% 50%;
}

.barkhausen-logo {
  path {
    @apply transition-all duration-200 ease-in-out;
  }

  &:hover path {
    fill: #fff;
  }
}

.hot-spot {
  z-index: 0;

  &:hover {
    z-index: 600;
  }
}

.color-filter {
  filter: contrast(70%) brightness(130%) saturate(65%);
}
</style>
