<template>
  <div class="fixed inset-0 flex items-center justify-center whole-image">
    <blur-overlay
      class="absolute z-0 inset-0"
      @click="$emit('close')"
    ></blur-overlay>
    <div class="z-10 w-full h-full">
      <div
        class="whole-image-panzoom w-full h-full flex items-center justify-center"
        ref="panzoom"
        @wheel="panzoom.zoomWithWheel"
      >
        <img
          alt=""
          src="wimmelbild-sm.jpg"
          class="shadow-custom-sm pointer-events-none"
          ref="panzoom"
        />
      </div>
      <CloseButton
        @click="$emit('close')"
        class="absolute right-0 bottom-0 mb-16 mx-3 sm:bottom-auto sm:top-0 sm:m-3"
      />
    </div>
  </div>
</template>

<script>
import Panzoom from '@panzoom/panzoom'

import BlurOverlay from './BlurOverlay'
import CloseButton from './CloseButton'

export default {
  components: { CloseButton, BlurOverlay },
  emits: ['close'],
  name: 'WholeImage',
  data() {
    return {
      panzoom: null
    }
  },
  methods: {
    onClickOutside() {
      this.$emit('close')
    }
  },
  mounted() {
    this.panzoom = Panzoom(this.$refs.panzoom, {
      contain: 'outside',
      minScale: 1,
      cursor: 'default'
    })
  }
}
</script>

<style lang="scss">
.whole-image {
  .blur {
    background: rgba(0, 0, 0, 0.85);
  }
  img {
    max-width: calc(100vh * 6005 / 4717);
    max-height: 100vh;
    width: 100%;
    height: auto;
    @apply mx-auto;
  }
}
</style>
