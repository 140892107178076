import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state() {
    return {
      categories: [
        {
          id: 1,
          name: 'mobility',
          displayNameDE: 'Mobilität',
          displayNameEN: 'Mobility',
          color: 'yellow'
        },
        {
          id: 2,
          name: 'health',
          displayNameDE: 'Gesundheit',
          displayNameEN: 'Health',
          color: 'red'
        },
        {
          id: 3,
          name: 'industry',
          displayNameDE: 'Industrie & Landwirtschaft',
          displayNameEN: 'Industry & Agriculture',
          color: 'orange'
        },
        {
          id: 4,
          name: 'living',
          displayNameDE: 'Wohnen',
          displayNameEN: 'Living',
          color: 'teal'
        },
        {
          id: 5,
          name: 'routes',
          displayNameDE: 'Routenplanung',
          displayNameEN: 'Route planning',
          color: 'gray-blue'
        },
        {
          id: 6,
          name: 'energy',
          displayNameDE: 'Energie',
          displayNameEN: 'Energy',
          color: 'purple'
        },
        {
          id: 7,
          name: 'green',
          displayNameDE: 'Grünflächen',
          displayNameEN: 'Green spaces',
          color: 'green'
        },
        {
          id: 8,
          name: 'privacy',
          displayNameDE: 'Datenschutz & Datensicherheit',
          displayNameEN: 'Data protection & data security',
          color: 'blue'
        },
        {
          id: 9,
          name: 'communication',
          displayNameDE: 'Mobilkommunikation',
          displayNameEN: 'Mobile communication',
          color: 'gray'
        }
      ],
      hotSpots: [],
      scale: 1,
      tutorialActive: true
    }
  },
  mutations: {
    setScale(state, scale) {
      state.scale = scale
    },
    setHotSpots(state, hotSpots) {
      state.hotSpots = hotSpots
    }
  },
  actions: {
    async fetchHotSpots({ commit, state }) {
      const response = await axios.get('/?type=710831101')
      commit(
        'setHotSpots',
        response.data.hotspots.map(hotSpot => {
          return {
            ...hotSpot,
            category: state.categories[hotSpot.category - 1],
            titleDE: hotSpot.titleDe,
            titleEN: hotSpot.titleEn,
            summaryDE: hotSpot.summaryDe,
            summaryEN: hotSpot.summaryEn,
            contentDE: hotSpot.contentDe,
            contentEN: hotSpot.contentEn
          }
        })
      )
    }
  },
  modules: {}
})
