<template>
  <button @click="switchLanguage">
    <span class="focus:outline-none" tabindex="-1">
      <blur-overlay class="language-switch shadow-custom-sm cursor-pointer">
        <span
          class="toggle-bg"
          :class="{
            'toggle-de': $i18n.locale === 'de',
            'toggle-en': $i18n.locale === 'en'
          }"
        >
          <span class="toggle-bg-inner"></span>
        </span>
        <span
          class="ml-2 inline-flex items-center justify-center transition duration-500 ease-in-out"
          :class="{ 'text-gray-800 font-semibold': $i18n.locale === 'en' }"
          >EN</span
        >
        <span
          class="mr-2 inline-flex items-center justify-center transition duration-500 ease-in-out"
          :class="{ 'text-gray-800 font-semibold': $i18n.locale === 'de' }"
          >DE</span
        >
      </blur-overlay>
    </span>
  </button>
</template>

<script>
import BlurOverlay from './BlurOverlay'
export default {
  name: 'LanguageSwitch',
  components: { BlurOverlay },
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.$i18n.locale === 'de' ? 'en' : 'de'
    }
  }
}
</script>

<style lang="scss" scoped>
.language-switch {
  width: 72px;
  height: 35px;
  background: rgba(180, 180, 180, 0.4);

  @apply rounded-full flex items-center justify-between text-white font-medium text-sm relative;

  &:hover .toggle-bg-inner {
    @apply shadow-white;
  }
}
.toggle-bg {
  z-index: -1;
  width: 35px;
  height: 35px;

  @apply shadow-custom-sm absolute rounded-full left-0 transition-all duration-500 ease-in-out flex items-center justify-center;

  &-inner {
    width: 35px;
    height: 35px;
    @apply rounded-full bg-white transition-all duration-500 ease-in-out;
  }

  &.toggle-de {
    transform: translateX(37px);
  }
  &.toggle-en {
    transform: translateX(0);
  }
}
</style>
