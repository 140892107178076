<template>
  <transition
    leave-active-class="transition duration-700 ease-in-out"
    leave-from-class="transform scale-100 opacity-1"
    leave-to-class="transform scale-105 opacity-0"
  >
    <blur-overlay
      v-if="tutorialActive"
      @click="onClick"
      class="initial-tutorial-element"
    >
      <div
        class="rounded-full w-full h-full flex items-center justify-center bg-white"
      >
        <div class="initial-tutorial-element-content">
          <span
            class="uppercase font-semibold tracking-wide text-center p-2 leading-tight"
            >{{ $t('general.explore') }}</span
          >
          <BaseIcon
            class="absolute top-0 left-0 h-5 w-5 transform rotate-45"
            name="arrow-left"
          />
          <BaseIcon
            class="absolute top-0 right-0 h-5 w-5 transform rotate-135"
            name="arrow-left"
          />
          <BaseIcon
            class="absolute bottom-0 right-0 h-5 w-5 transform -rotate-135"
            name="arrow-left"
          />
          <BaseIcon
            class="absolute bottom-0 left-0 h-5 w-5 transform -rotate-45"
            name="arrow-left"
          />
        </div>
      </div>
    </blur-overlay>
  </transition>
</template>

<script>
import BlurOverlay from './BlurOverlay'
export default {
  name: 'InitialTutorialElement',
  components: { BlurOverlay },
  computed: {
    tutorialActive() {
      return this.$store.state.tutorialActive
    }
  },
  methods: {
    onClick() {
      this.$store.state.tutorialActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
.initial-tutorial-element {
  width: 240px;
  height: 240px;
  @apply p-6 shadow-custom-sm cursor-pointer transition duration-200 ease-in-out rounded-full flex items-center justify-center;

  &-content {
    width: 130px;
    height: 130px;
    @apply relative flex items-center justify-center text-gray-contrast;
  }
}
</style>
