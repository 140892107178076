<template>
  <h2>Impressum</h2>
  <h3>Anbieterkennzeichnung</h3>
  <p>
    Barkhausen Institut gGmbH<br />
    Würzburger Str. 46<br />
    01187 Dresden<br />
    Tel.: +49 351 46339161<br />
    E-Mail: info(at)barkhauseninstitut.org
  </p>
  <p>
    Vertretungsberechtigter Geschäftsführer: Prof. Dr. Gerhard Fettweis
  </p>
  <p>Handelsregister: Amtsgericht Dresden, HRB 37267</p>
  <p>
    Diese Maßnahme wird mitfinanziert mit Steuermitteln auf Grundlage des von
    den Abgeordneten des Sächsischen Landtags beschlossenen Haushaltes.
  </p>
  <h3>Inhaltlich Verantwortlicher</h3>
  <p>Prof. Dr. Gerhard Fettweis</p>
  <h3>Technische Betreuung und Webdesign</h3>
  <p>
    Ö GRAFIK<br />
    Agentur für marketing und design<br />
    Wittenberger Straße 114 A<br />
    01277 Dresden<br />
    E-Mail: info@oe-grafik.de www.oe-grafik.de
  </p>
  <h3>Datenschutzbeauftragte der Barkhausen Institut gGmbH</h3>
  <p>
    Kerstin Herschel, Externe Datenschutzbeauftragte<br />
    PRODATIS CONSULTING AG<br />
    Landhausstraße 8<br />
    01067 Dresden<br />
    Telefon: +49 351 2662330<br />
    E-Mail: <a href="mailto:k.herschel@prodatis.com">k.herschel@prodatis.com</a>
  </p>
  <h3>Haftungsausschluss</h3>
  <p>
    Wir bemühen uns intensiv, auf allen Webseiten richtige und vollständige
    Informationen zur Verfügung zu stellen. Wir übernehmen jedoch keine Haftung
    oder Garantie für die Aktualität, Richtigkeit oder Vollständigkeit der auf
    den Webseiten bereitgestellten Informationen. Dies gilt auch für alle
    Verbindungen (Hyperlinks), auf die die Webseiten direkt oder indirekt
    verweisen. Die Barkhausen Institut gGmbH hat keinen Einfluss auf die
    aktuelle und zukünftige Gestaltung und auf Inhalte der gelinkten Seiten. Wir
    sind für den Inhalt einer Seite, die mit einem solchen Link erreicht wird,
    nicht verantwortlich.
  </p>

  <h3>Urheberrecht und Bildnachweis</h3>
  <p>
    Alle im Internetangebot der Barkhausen Institut gGmbH veröffentlichten
    Inhalte (Layout, Texte, Bilder, Grafiken usw.) unterliegen dem Urheberrecht.
    Jede vom Urheberrechtsgesetz nicht zugelassene Verwertung bedarf vorheriger
    ausdrücklicher Zustimmung. Dies gilt insbesondere für Vervielfältigung,
    Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von
    Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Wir
    erlauben ausdrücklich und begrüßen das Zitieren unserer Dokumente und
    Webseiten sowie das Setzen von Links auf unsere Website. Alle innerhalb des
    Internetangebotes genannten und ggf. durch Dritte geschützten Marken und
    Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils
    gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen
    eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der
    Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt
    sind.
  </p>

  <p></p>
</template>

<script>
export default {
  name: 'Imprint'
}
</script>
