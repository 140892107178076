<template>
  <button class="zoom-button shadow-custom-sm">
    <span class="zoom-button-inner" tabindex="-1">
      <BaseIcon
        :name="zoomAction === 'in' ? 'zoom-in' : 'zoom-out'"
        class="icon"
      />
    </span>
  </button>
</template>

<script>
export default {
  name: 'ZoomButton',
  props: {
    zoomAction: {
      type: String,
      validator: value => {
        return ['in', 'out'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom-button {
  width: 35px;
  height: 35px;
  @apply rounded-full;

  &:hover &-inner {
    @apply shadow-white;
  }

  &-inner {
    @apply w-full h-full text-gray-700 text-2xl rounded-full bg-white transition duration-500 ease-in-out flex items-center justify-center focus:outline-none;

    .icon {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
