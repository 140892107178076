<template>
  <main>
    <InteractiveGraphic />
  </main>
</template>

<script>
import InteractiveGraphic from './components/InteractiveGraphic'

export default {
  name: 'App',
  components: { InteractiveGraphic },
  mounted() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'noto-sans', system-ui, -apple-system, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-900;
}

.bg-category {
  &-1 {
    @apply bg-yellow-500;
  }
  &-2 {
    @apply bg-red-500;
  }
  &-3 {
    @apply bg-orange-500;
  }
  &-4 {
    @apply bg-teal-500;
  }
  &-5 {
    @apply bg-gray-blue-500;
  }
  &-6 {
    @apply bg-purple-500;
  }
  &-7 {
    @apply bg-green-500;
  }
  &-8 {
    @apply bg-blue-500;
  }
  &-9 {
    @apply bg-gray-500;
  }
}

.text-category {
  &-1 {
    @apply text-yellow-500;
  }
  &-2 {
    @apply text-red-500;
  }
  &-3 {
    @apply text-orange-500;
  }
  &-4 {
    @apply text-teal-500;
  }
  &-5 {
    @apply text-gray-blue-500;
  }
  &-6 {
    @apply text-purple-500;
  }
  &-7 {
    @apply text-green-500;
  }
  &-8 {
    @apply text-blue-500;
  }
  &-9 {
    @apply text-gray-500;
  }
}
</style>
