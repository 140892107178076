<template>
  <BlurOverlay class="fixed bottom-0 left-0 right-0">
    <div class="w-full h-full relative bg-white xl:px-6 xl:py-4">
      <div class="flex z-20 relative items-center justify-between">
        <slot name="items"></slot>
      </div>
    </div>
  </BlurOverlay>
</template>

<script>
import BlurOverlay from '../BlurOverlay'
export default {
  name: 'FilterMenu',
  components: { BlurOverlay }
}
</script>

<style scoped></style>
