<template>
  <div class="loading-screen overflow-auto">
    <div
      class="loading-screen-wrapper sm:absolute text-white px-4 min-h-screen"
    >
      <div
        class="sm:absolute sm:top-0 sm:left-0 sm:right-0 flex items-start justify-center sm:justify-between p-6"
      >
        <h1
          class="hidden sm:flex flex-col text-3xl font-semibold text-white items-start leading-8"
        >
          <span class="pl-3 pr-5 pt-2 pb-3">
            {{ $t('general.iot.beforeBreak') }}
          </span>
          <span class="pl-3 pr-5 pb-3">{{ $t('general.iot.afterBreak') }}</span>
        </h1>
        <a
          href="https://www.barkhauseninstitut.org/"
          target="_blank"
          rel="noopener"
          ><img
            alt="Barkhausen-Logo"
            src="barkhausen-logo-w.svg"
            class="h-10 lg:h-12"
        /></a>
      </div>
      <div class="flex flex-col items-center flex-1 sm:flex-none mt-2 sm:mt-10">
        <h2
          class="uppercase text-2xl sm:text-3xl tracking-wide font-semibold text-center leading-tight"
        >
          {{ $t('loadingScreen.headline') }}
        </h2>
        <div
          class="mt-6 max-w-4xl font-normal text-center sm:text-lg tracking-wide leading-relaxed"
        >
          <p>{{ $t('loadingScreen.introText.p1') }}</p>
          <p class="mt-3">{{ $t('loadingScreen.introText.p2') }}</p>
        </div>
        <div class="mt-12 text-center">
          <transition
            mode="out-in"
            enter-active-class="transition duration-500 ease-in-out"
            enter-from-class="transition duration-500 ease-in-out opacity-0"
            enter-to-class="transition duration-500 ease-in-out opacity-100"
            leave-active-class="transition duration-500 ease-in-out"
            leave-from-class="transition duration-500 ease-in-out opacity-100"
            leave-to-class="transition duration-500 ease-in-out opacity-0"
          >
            <div class="flex flex-col items-center" v-if="isLoading">
              <svg
                class="animate-spin h-12 w-12 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <ChangingLoadingMessages
                class="mt-3"
                :messages="[
                  {
                    de: 'Windräder werden gepflanzt',
                    en: 'Pinwheels are being planted'
                  },
                  {
                    de: 'Satellitenschüsseln werden gespült',
                    en: 'Satellite dishes are being washed'
                  },
                  {
                    de: 'Bäume werden aufgestellt',
                    en: 'Trees are being set up'
                  },
                  {
                    de: 'Straßen werden ausgerollt',
                    en: 'Streets are being rolled out'
                  }
                ]"
              />
            </div>
            <button
              v-else
              class="close-loading-screen mt-32"
              @click="$emit('close')"
            >
              <img
                alt=""
                class="bird-loading-finished"
                src="/bird-loading-finished.svg"
              />
              <span
                tabindex="-1"
                class="inline-flex items-center justify-center bg-blue-100 text-black uppercase tracking-wide font-semibold px-6 py-3 rounded-full shadow-custom-sm transition duration-150 ease-in-out"
              >
                {{ $t('loadingScreen.buttonText') }}
              </span>
            </button>
          </transition>
        </div>
      </div>
      <div
        class="mt-6 sm:mt-0 sm:absolute sm:bottom-0 sm:left-0 sm:right-0 flex flex-col sm:flex-row items-center sm:items-end justify-between p-6 text-white uppercase text-xs tracking-wide"
      >
        <div class="sm:hidden mb-6">
          <LanguageSwitch />
        </div>
        <ul class="flex justify-between w-full sm:w-auto space-x-8">
          <li>
            <a
              class="transition duration-200 ease-in-out hover:text-blue-300"
              :href="$t('urls.barkhausenImprint')"
              target="_blank"
              rel="noopener"
              >{{ $t('general.imprint') }}</a
            >
          </li>
          <li>
            <a
              class="transition duration-200 ease-in-out hover:text-blue-300"
              :href="$t('urls.barkhausenPrivacy')"
              target="_blank"
              rel="noopener"
              >{{ $t('general.privacy') }}</a
            >
          </li>
        </ul>
        <div class="mt-4 sm:mt-0 flex flex-col items-end">
          <div class="mb-6 hidden sm:block">
            <LanguageSwitch />
          </div>
          <span>© Barkhausen Institut 2020</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChangingLoadingMessages from './ChangingLoadingMessages'
import LanguageSwitch from './hud-elements/LanguageSwitch'
export default {
  name: 'LoadingScreen',
  components: { LanguageSwitch, ChangingLoadingMessages },
  props: {
    isLoading: { type: Boolean, default: true }
  },
  data() {
    return {
      isLoadingScreenActive: true
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-screen {
  @apply w-full h-full fixed top-0;

  background-image: url('/wimmelbild-bg-loading.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  &-wrapper {
    @apply bg-blue-500 bg-opacity-90 inset-0 flex flex-col items-center justify-center;

    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }

  .bird-loading-finished {
    @apply absolute top-0 left-0 w-48;

    transform: rotate(9deg) translate(-32%, -83%);
  }

  button.close-loading-screen {
    @apply relative;

    &:hover span {
      @apply bg-blue-200;
    }

    &:focus span {
      @apply bg-white outline-none;
    }
  }
}
</style>
