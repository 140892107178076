<template>
  <button
    ref="marker"
    class="map-marker-wrapper"
    :aria-describedby="
      `tooltip-${hotSpot[`title${$i18n.locale.toUpperCase()}`]}`
    "
    v-click-outside="onClickOutside"
    @click="onClick"
    @touchendclick="onTouchendClick"
    @mouseover="onMouseover"
    @mouseleave="isHovered = false"
  >
    <span tabindex="-1" class="map-marker relative" ref="hoverable">
      <span
        class="map-marker-default"
        :class="
          isCategoryActive
            ? `category-active overlay-${hotSpot.category.color}`
            : ''
        "
      >
        <blur-overlay class="map-marker-default-blur"></blur-overlay>
        <span
          class="map-marker-default-inner h-4 w-4 bg-white rounded-full flex items-center justify-center z-10"
        >
          <span
            class="h-2 w-2 rounded-full"
            :class="`bg-${hotSpot.category.color}-500`"
          ></span>
        </span>
      </span>
      <blur-overlay class="map-marker-hovered">
        <span
          ref="hoverHelper"
          :class="['hover-helper', tooltipPositionClass]"
        ></span>
        <span
          class="map-marker-hovered-inner"
          :class="`bg-${hotSpot.category.color}-500`"
        >
          <BaseIcon :name="hotSpot.category.name" />
        </span>
        <div
          :id="`tooltip-${hotSpot[`title${$i18n.locale.toUpperCase()}`]}`"
          class="marker-tooltip"
          :class="tooltipPositionClass"
          role="tooltip"
          ref="tooltip"
        >
          <p class="text-xs font-bold leading-tight mb-1">
            {{ hotSpot.category[`displayName${$i18n.locale.toUpperCase()}`] }}
          </p>
          <h3 :class="`font-bold text-${hotSpot.category.color}-contrast`">
            {{ hotSpot[`title${$i18n.locale.toUpperCase()}`] }}
          </h3>
          <p class="text-sm mt-2">
            {{ hotSpot[`summary${$i18n.locale.toUpperCase()}`] }}
          </p>
          <button
            :class="
              `mt-4 uppercase tracking-wide font-bold text-xs text-${hotSpot.category.color}-contrast focus:outline-none`
            "
          >
            {{ $t('general.open') }}
          </button>
        </div>
      </blur-overlay>
    </span>
  </button>
</template>

<script>
import BlurOverlay from './BlurOverlay'

export default {
  name: 'MapMarker',
  components: { BlurOverlay },
  props: {
    hotSpot: { required: true },
    isCategoryActive: { type: Boolean, default: false },
    tooltipPlacement: { type: String, default: 'right' }
  },
  emits: ['open'],
  data() {
    return {
      isHovered: false,
      popperInstance: null,
      tooltipPositionClass: '',
      touchCount: 0
    }
  },
  mounted() {
    this.isHovered = false
    this.tooltipPositionClass = this.tooltipPlacement.split('-').join(' ')
    this.touchCount = 0
  },
  methods: {
    onClick() {
      if ('ontouchstart' in window || navigator.maxTouchPoints) {
        if (this.touchCount === 1) {
          this.$emit('open', this.getWindowOffset())
          this.touchCount = 0
        } else this.touchCount = 1
      } else this.$emit('open', this.getWindowOffset())
    },
    onTouchendClick() {},
    onClickOutside() {
      if ('ontouchstart' in window || navigator.maxTouchPoints)
        this.touchCount = 0
    },
    async onMouseover() {
      this.isHovered = true
      this.tooltipPositionClass = this.getTooltipPosition()

      this.$refs.hoverHelper.style.height =
        this.$refs.tooltip.scrollHeight + 'px'

      setTimeout(() => {
        const tooltipPanning = this.getTooltipPanning()

        if (tooltipPanning) {
          this.$emit('tooltip-pan', this.getTooltipPanning())
        }
      }, 200)
    },
    getWindowOffset() {
      return {
        top: this.$refs.marker.getBoundingClientRect().y,
        left: this.$refs.marker.getBoundingClientRect().x
      }
    },
    getTooltipPosition() {
      let position = []
      const windowOffset = this.getWindowOffset()

      position[0] = windowOffset.left < window.innerWidth / 2 ? 'right' : 'left'
      position[1] = windowOffset.top < window.innerHeight / 2 ? 'start' : 'end'

      if (window.innerWidth < 768 && this.hotSpot.markerPositionX <= 24)
        position[0] = 'right'
      if (window.innerWidth < 768 && this.hotSpot.markerPositionX >= 82)
        position[0] = 'left'

      return position.join(' ')
    },
    getTooltipPanning() {
      const tooltipOffset = {
        top: this.$refs.tooltip.getBoundingClientRect().y,
        left: this.$refs.tooltip.getBoundingClientRect().x
      }
      let panX = 0
      let panY = 0
      if (tooltipOffset.left < 0) panX = Math.abs(tooltipOffset.left) + 10
      if (tooltipOffset.left > window.innerWidth - 215)
        panX =
          window.innerWidth -
          tooltipOffset.left -
          215 -
          10 -
          (16.667 * this.$store.state.scale - 16.667)

      return panX === 0 && panY === 0 ? false : { x: panX, y: panY }
    }
  }
}
</script>

<style lang="scss" scoped>
.map-marker {
  &-default,
  &-hovered {
    animation: fade 0.5s;
    top: 50%;
    left: 50%;
    @apply absolute transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center rounded-full;
  }

  &-default {
    height: 30px;
    width: 30px;
    @apply flex transition-all duration-500 ease-in-out;

    &-blur {
      height: 30px;
      width: 30px;
      animation: pulse 2s ease-in-out infinite;
      @apply absolute rounded-full z-0;
    }

    &:after {
      content: '';
      width: 50px;
      height: 50px;
      top: 50%;
      left: 50%;
      opacity: 0;
      @apply absolute transform -translate-x-1/2 -translate-y-1/2 transition-all duration-500 ease-in-out rounded-full;
    }

    &.category-active {
      @apply scale-150;

      &:after {
        @apply opacity-100 border border-white;
      }

      &.overlay-yellow {
        &:after {
          @apply bg-yellow-500 bg-opacity-75;
        }
      }

      &.overlay-red {
        &:after {
          @apply bg-red-500 bg-opacity-75;
        }
      }

      &.overlay-orange {
        &:after {
          @apply bg-orange-500 bg-opacity-75;
        }
      }

      &.overlay-teal {
        &:after {
          @apply bg-teal-500 bg-opacity-75;
        }
      }

      &.overlay-blue {
        &:after {
          @apply bg-blue-500 bg-opacity-75;
        }
      }

      &.overlay-gray-blue {
        &:after {
          @apply bg-gray-blue-500 bg-opacity-75;
        }
      }

      &.overlay-purple {
        &:after {
          @apply bg-purple-500 bg-opacity-75;
        }
      }

      &.overlay-green {
        &:after {
          @apply bg-green-500 bg-opacity-75;
        }
      }

      &.overlay-gray {
        &:after {
          @apply bg-gray-500 bg-opacity-75;
        }
      }
    }
  }

  &-hovered {
    height: 90px;
    width: 90px;
    padding: 10px;
    @apply hidden;

    &-inner {
      @apply w-full h-full rounded-full;
    }
  }

  &:hover &-default,
  &-wrapper:focus &-default {
    @apply hidden;
  }

  &:hover &-hovered,
  &-wrapper:focus &-hovered {
    @apply flex;
  }
}

.marker-tooltip {
  min-width: 215px;
  z-index: 9999;

  @apply absolute bg-white p-4 text-left;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 12px;
    border-color: transparent transparent transparent #ffffff;

    @apply absolute right-0 transform;
  }

  &.start {
    /*margin-top: 90px;*/
    @apply top-0;

    &:before {
      @apply top-0 translate-y-8;
    }
  }

  &.end {
    /*margin-bottom: 90px;*/
    @apply bottom-0;

    &:before {
      @apply bottom-0 -translate-y-8;
    }
  }

  &.left {
    margin-right: 90px + 10px + 12px;
    @apply right-0;

    &:before {
      --tw-translate-x: 97%;
      @apply right-0;
    }
  }

  &.right {
    margin-left: 90px + 10px + 12px;
    @apply left-0;

    &:before {
      --tw-scale-x: -1;
      --tw-translate-x: -97%;
      @apply left-0;
    }
  }

  &.top {
    margin-bottom: 90px + 10px + 12px;
    @apply bottom-0;

    &:before {
      right: 50%;
      --tw-translate-y: 68%;
      --tw-translate-x: 50%;
      @apply bottom-0 rotate-90;
    }
  }

  &.bottom {
    margin-top: 90px + 10px + 12px;
    @apply top-0;

    &:before {
      right: 50%;
      --tw-translate-y: -68%;
      --tw-translate-x: 50%;
      @apply top-0 -rotate-90;
    }
  }
}

.hover-helper {
  @apply bg-red-500 bg-opacity-0 w-64 absolute transform;

  &.left {
    --tw-translate-x: -90px;
    @apply right-0;
  }

  &.right {
    --tw-translate-x: 90px;
    @apply left-0;
  }

  &.top {
    --tw-translate-y: -90px;
    @apply bottom-0;
  }

  &.bottom {
    --tw-translate-y: 90px;
    @apply top-0;
  }

  &.start {
    @apply top-0;
  }

  &.end {
    @apply bottom-0;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    background: rgba(255, 255, 255, 0.4);
  }
  50% {
    transform: scale(1.2);
    background: rgba(255, 255, 255, 0.6);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
