<template>
  <button
    class="relative inline-flex items-center justify-center px-3 py-2 leading-none rounded-full text-black uppercase font-semibold text-xs tracking-wide cursor-pointer transition duration-200 ease-in-out"
    @click="onClick"
    ref="button"
    :class="`category-${category.id}`"
  >
    <span class="focus:outline-none" tabindex="-1"
      ><span
        ref="itemBg"
        class="item-bg transition-all duration-500 ease-in-out"
        :style="itemBgStyle"
      ></span>
      {{ category[`displayName${$i18n.locale.toUpperCase()}`] }}</span
    >
  </button>
</template>

<script>
export default {
  name: 'FilterMenuItem',
  props: {
    category: { type: Object, required: true },
    isActive: { type: Boolean, required: true }
  },
  computed: {
    scrollWidth() {
      return this.$refs.button ? this.$refs.button.scrollWidth + 'px' : '0'
    },
    itemBgStyle() {
      return {
        maxWidth: this.isActive ? this.scrollWidth : '28px'
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('itemclick', this.category.id)
    }
  },
  mounted() {
    this.$refs.itemBg.style.maxWidth = this.isActive
      ? this.$refs.button.scrollWidth + 'px'
      : '28px'
  }
}
</script>

<style lang="scss" scoped>
.item-bg {
  z-index: -1;
  @apply rounded-full absolute left-0 inset-y-0 w-full;
}

.category {
  &-1 {
    .item-bg {
      @apply bg-yellow-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.yellow.200');
      }
    }
  }
  &-2 {
    .item-bg {
      @apply bg-red-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.red.200');
      }
    }
  }
  &-3 {
    .item-bg {
      @apply bg-orange-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.orange.200');
      }
    }
  }
  &-4 {
    .item-bg {
      @apply bg-teal-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.teal.200');
      }
    }
  }
  &-5 {
    .item-bg {
      @apply bg-gray-blue-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.gray-blue.200');
      }
    }
  }
  &-6 {
    .item-bg {
      @apply bg-purple-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.purple.200');
      }
    }
  }
  &-7 {
    .item-bg {
      @apply bg-green-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.green.200');
      }
    }
  }
  &-8 {
    .item-bg {
      @apply bg-blue-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.blue.200');
      }
    }
  }
  &-9 {
    .item-bg {
      @apply bg-gray-500;
    }
    &:hover {
      .item-bg {
        box-shadow: 0 0 0 8px theme('colors.gray.200');
      }
    }
  }
}
</style>
