<template>
  <inline-svg :src="require(`../assets/icons/${name}.svg`)"></inline-svg>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'BaseIcon',
  components: {
    InlineSvg
  },
  props: {
    name: { type: String, required: true }
  }
}
</script>

<style scoped></style>
