<template>
  <div class="hot-spot-detail">
    <div class="w-full h-full bg-white shadow-xl flex flex-col relative">
      <div
        class="relative h-64 text-gray-400 flex items-center justify-center inset-0 hover:text-white cursor-pointer transition duration-200 ease-in-out"
        @click="playVideo"
      >
        <img
          class="absolute inset-0 z-0 w-full h-full object-cover object-center"
          src="wimmelbild-thumbnail.jpg"
          alt=""
        />
        <BaseIcon name="play" class="h-16 w-16 z-10" />
      </div>
      <div
        class="py-3 md:py-4 px-6 relative hot-spot-detail-top-bar flex items-center"
        :class="`bg-${hotSpot.category.color}-500`"
      >
        <button
          class="inline-flex items-center focus:outline-none text-black transition duration-200 ease-in-out"
          :class="`hover:text-${hotSpot.category.color}-800`"
          @click="$emit('reset')"
          :aria-label="$t('general.back')"
        >
          <BaseIcon name="triangle-back" class="w-3 h-3" />
          <span class="text-xs uppercase font-semibold tracking-wide ml-2">{{
            $t('general.back')
          }}</span>
        </button>
        <span
          class="icon w-24 h-24 md:w-32 md:h-32 shadow-custom-sm"
          :class="`bg-${hotSpot.category.color}-300`"
        >
          <BaseIcon :name="hotSpot.category.name" class="w-full h-full" />
        </span>
      </div>
      <div class="hot-spot-detail-content">
        <p class="text-sm font-bold leading-tight">
          {{ hotSpot.category[`displayName${$i18n.locale.toUpperCase()}`] }}
        </p>
        <h3
          class="font-bold text-2xl leading-tight mt-2"
          :class="`text-${hotSpot.category.color}-contrast`"
        >
          {{ hotSpot[`title${$i18n.locale.toUpperCase()}`] }}
        </h3>
        <div
          class="hot-spot-detail-content-text"
          v-html="hotSpot[`content${$i18n.locale.toUpperCase()}`]"
        ></div>
        <a
          v-if="hotSpot.linkUrl"
          :class="
            `mt-4 uppercase tracking-wide font-semibold text-sm text-${hotSpot.category.color}-contrast hover:opacity-75 transition duration-200 ease-in-out`
          "
          :href="hotSpot.linkUrl"
          target="_blank"
          rel="noopener"
        >
          {{ $t('general.externalLink') }}
        </a>
      </div>
      <CloseButton
        class="absolute top-0 right-0 -m-3 lg:-m-6"
        @click="$emit('close')"
      />
    </div>
    <transition
      enter-active-class="transition duration-200 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition delay-100 duration-200 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="videoIsPlaying"
        class="bg-black bg-opacity-50 fixed inset-0 z-40"
      ></div>
    </transition>
    <transition
      enter-active-class="transform transition delay-100 duration-300 ease-in-out"
      enter-from-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transform transition duration-300 ease-in-out"
      leave-from-class="opacity-100 scale-10"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        v-if="videoIsPlaying"
        class="fixed inset-0 sm:p-8 flex items-center justify-center z-50"
      >
        <video
          ref="video"
          :src="
            'https://www.barkhauseninstitut.org/fileadmin' + hotSpot.videoUrl
          "
          class="hot-spot-video focus:outline-none shadow-custom-sm"
          autoplay
          :controls="videoIsPlaying"
          controlsList="nodownload"
          poster="wimmelbild-thumbnail.jpg"
          @play="videoIsPlaying = true"
        />
        <CloseButton
          class="absolute top-0 right-0 m-3"
          @click="videoIsPlaying = false"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import CloseButton from './CloseButton'
export default {
  name: 'HotSpotDetail',
  components: { CloseButton },
  emits: ['close'],
  props: {
    hotSpot: { type: Object, required: true }
  },
  data() {
    return {
      videoIsPlaying: false
    }
  },
  methods: {
    playVideo() {
      this.videoIsPlaying = true
    }
  }
}
</script>

<style lang="scss" scoped>
.hot-spot-detail {
  max-width: 598px;
  @apply w-full fixed top-0 left-0 bottom-0 right-0 md:right-auto m-auto md:m-0 p-4;

  @screen lg {
    padding: 50px 54px;
  }

  &-top-bar {
    .icon {
      top: 50%;
      padding: 10px;
      @apply absolute right-0 mr-5 rounded-full transform -translate-y-1/2 flex items-center justify-center;

      @screen md {
        padding: 13px;
      }
    }
  }

  &-content {
    @apply mr-3 my-5 py-3 px-6 flex flex-col items-start overflow-auto;

    &::-webkit-scrollbar {
      width: 8px;

      &-thumb {
        @apply rounded-full bg-gray-400;

        &:hover {
          @apply bg-gray-500;
        }
      }
      &-track {
        @apply bg-transparent;
      }
    }

    &-text {
      @apply text-sm mt-3 mb-4 pr-6 w-full;

      p + p {
        @apply mt-3;
      }
    }
  }
}

.hot-spot-video {
  max-height: calc(100vh - 4rem);
  margin: auto;
}
</style>
