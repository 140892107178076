import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createI18n } from 'vue-i18n'

import App from './App.vue'
import store from './store'
// import { makeServer } from './server'
import './assets/styles/tailwind.css'
import BaseIcon from '@/components/BaseIcon'
import { messages } from './i18n'

// makeServer()

const i18n = createI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'de',
  messages
})

const app = createApp(App).use(store)

app.component('BaseIcon', BaseIcon)

app.directive('click-outside', {
  // eslint-disable-next-line no-unused-vars
  beforeMount(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

axios.defaults.baseURL = 'https://www.barkhauseninstitut.org'
// axios.defaults.withCredentials = false
app.use(VueAxios, axios)

app.use(i18n)

app.mount('#app')
