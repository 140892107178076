<template>
  <div ref="blur" class="blur">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BlurOverlay'
}
</script>

<style scoped>
.blur {
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.4);
}
</style>
