<template>
  <span class="loading-message">
    {{ localMessages[activeMessage] }}
  </span>
</template>

<script>
export default {
  name: 'ChangingLoadingMessages',
  props: {
    messages: { type: Array, required: true }
  },
  data() {
    return {
      activeMessage: -1
    }
  },
  computed: {
    localMessages() {
      return this.messages.map(message => {
        return message[this.$i18n.locale]
      })
    }
  },
  mounted() {
    this.activeMessage = Math.floor(Math.random() * this.localMessages.length)
  },
  watch: {
    activeMessage() {
      setTimeout(() => {
        this.activeMessage =
          this.activeMessage === this.localMessages.length - 1
            ? 0
            : this.activeMessage + 1
      }, 6000)
    }
  }
}
</script>

<style scoped>
.loading-message {
  @apply font-medium tracking-wide uppercase;
  animation: pulse 6s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation-delay: 3s;
}

@keyframes pulse {
  0%,
  40%,
  60%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
