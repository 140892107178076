export const loadingScreen = {
  de: {
    headline: 'Willkommen in der interaktiven Welt des Internets der Dinge',
    introText: {
      p1:
        'Gehen Sie auf Entdeckungsreise durch eine Stadt, die Nachhaltigkeit und technische Entwicklung miteinander verbindet. Gesundheit, Mobilität, Landwirtschaft und weitere Bereiche unseres Alltags können bereits heute von der IoT-Technologie (Internet of Things) profitieren. Darüber hinaus werden nicht sichtbare Phänomene der Mobilkommunikation veranschaulicht.',
      p2: 'Das Team des Barkhausen Instituts'
    },
    buttonText: 'Jetzt Wimmelbild erkunden'
  },
  en: {
    headline: 'Welcome to the interactive world of the Internet of things',
    introText: {
      p1:
        'Go on a journey and discover a city that combines sustainability and technological development. Health, mobility, agriculture, and other parts of our everyday life can benefit already nowadays from IoT (Internet of Things) technology. Furthermore, invisible phenomena of mobile communication are illustrated.',
      p2: 'The team of the Barkhausen Institute'
    },
    buttonText: 'Explore wimmelbild now'
  }
}
