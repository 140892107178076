<template>
  <transition
    enter-active-class="transition duration-200 ease-in-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-200 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <blur-overlay
      v-if="expanded"
      class="h-screen w-screen fixed inset-0 z-20"
      @click="expanded = false"
    ></blur-overlay>
  </transition>
  <div
    class="h-full w-full z-10 transform transition duration-200 ease-in-out -ml-px"
    :class="{ 'z-30': expanded }"
    :style="styleObject"
  >
    <div
      class="flex items-center justify-between px-2 sm:px-4 h-full font-bold border border-gray-600 bg-white transform"
      @click="expanded = !expanded"
    >
      <span class="flex items-center">
        <span>{{ $t('general.categories') }}</span>
        <span v-if="activeCategory !== -1" class="flex items-center ml-2">
          <span
            class="w-6 h-6 rounded-full"
            :class="
              `bg-${
                categories.find(cat => cat.id === activeCategory).color
              }-400`
            "
          >
          </span>
          <span class="-ml-3 text-xs font-semibold"
            >{{
              categories
                .find(cat => cat.id === activeCategory)
                [`displayName${$i18n.locale.toUpperCase()}`].substring(0, 15)
            }}{{
              categories.find(cat => cat.id === activeCategory)[
                `displayName${$i18n.locale.toUpperCase()}`
              ].length > 15
                ? '...'
                : ''
            }}</span
          >
        </span>
      </span>
      <span class="ml-3">
        <BaseIcon
          :name="expanded ? 'close' : 'chevron-up'"
          class="w-4 h-4"
        ></BaseIcon>
      </span>
    </div>
    <div
      v-for="category in categories"
      :key="category"
      class="flex items-center px-2 h-full font-bold border border-gray-600 bg-white relative z-40"
    >
      <FilterMenuItem
        :category="category"
        :is-active="activeCategory === category.id"
        @itemclick="onFilterItemClick"
      />
    </div>
  </div>
</template>

<script>
import BlurOverlay from '../BlurOverlay'
import FilterMenuItem from '../filter-menu/FilterMenuItem'
export default {
  name: 'MobileFilterMenu',
  components: { FilterMenuItem, BlurOverlay },
  emits: ['categoryclick'],
  props: {
    categories: { type: Array, required: true },
    activeCategory: { type: Number, required: true }
  },
  data() {
    return {
      activeLanguage: 'de',
      expanded: false
    }
  },
  computed: {
    otherLanguage() {
      return this.activeLanguage === 'de' ? 'en' : 'de'
    },
    styleObject() {
      return {
        transform: `translateY(${
          this.expanded ? -48 * this.categories.length : 0
        }px)`
      }
    }
  },
  methods: {
    setActiveLanguage(lang) {
      this.expanded = false
      this.activeLanguage = lang
    },
    onFilterItemClick(id) {
      this.$emit('categoryclick', id)
    }
  }
}
</script>

<style scoped></style>
