export const urls = {
  de: {
    barkhausenHome: 'https://www.barkhauseninstitut.org/',
    barkhausenImprint: 'https://www.barkhauseninstitut.org/impressum',
    barkhausenPrivacy: 'https://www.barkhauseninstitut.org/data-privacy'
  },
  en: {
    barkhausenHome: 'https://www.barkhauseninstitut.org/en/',
    barkhausenImprint: 'https://www.barkhauseninstitut.org/en/impressum',
    barkhausenPrivacy: 'https://www.barkhauseninstitut.org/en/data-privacy'
  }
}
