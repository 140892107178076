import { general } from './i18n/general'
import { loadingScreen } from './i18n/loadingScreen'
import { urls } from './i18n/urls'

export const messages = {
  de: {
    general: general.de,
    loadingScreen: loadingScreen.de,
    urls: urls.de
  },
  en: {
    general: general.en,
    loadingScreen: loadingScreen.en,
    urls: urls.en
  }
}
