<template>
  <button class="menu-button group">
    <span class="menu-button-inner group-hover:shadow-white" tabindex="-1">
      {{ $t('general.categories') }}
      <BaseIcon
        name="chevron-up"
        class="ml-2 w-3 h-3 transform transition-transform ease-in-out duration-500"
        :class="{ 'icon-active': isFilterMenuActive }"
      />
    </span>
  </button>
</template>

<script>
export default {
  name: 'FilterButton',
  props: {
    isFilterMenuActive: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.menu-button {
  @apply rounded-full cursor-pointer;

  &-inner {
    height: 35px;

    @apply shadow-custom-sm flex items-center bg-white rounded-full px-4 py-1 text-sm text-gray-800 uppercase font-semibold tracking-wide transition duration-500 ease-in-out focus:outline-none;

    .icon-active {
      transform: scaleY(-1);
    }
  }
}
</style>
